import { useParamsWithDefault } from "@smart/itops-hooks-dom";

import { SubmissionCreateView } from "../components";

export const CreateSubmission = () => {
  const { encodedParam, slugId, teamId } = useParamsWithDefault((p) => ({
    encodedParam: p.encodedParam || undefined,
    slugId: p.slugId || undefined,
    teamId: p.teamId || undefined,
  }));

  return (
    <SubmissionCreateView
      encodedParam={encodedParam}
      slugId={slugId}
      teamId={teamId}
    />
  );
};
