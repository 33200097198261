import { system } from "@smart/bridge-types-basic";
import { buildCloudfrontDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { zone } from "./zone";

export const cdn = buildCloudfrontDef({
  zone,
  namespace: buildNamespace({ system, entity: "cdn" }),
  subdomain: "cdn",
  fallbackRoute: undefined,
});
