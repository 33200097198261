import { buildAppBucketDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { cdn } from "./cloudfront";

export const appBucket = buildAppBucketDef({
  cdn,
  namespace: buildNamespace({ system, entity: "intake" }),
  prefix: undefined,
  pkg: "@smart/intake-app-web",
  port: 9100,
});
