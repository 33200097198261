import { z } from "zod";

import { planInfoSchema } from "@smart/bridge-domains-basic";
import { system } from "@smart/bridge-types-basic";
import { buildSNSSQSDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const QuestionGenerationNS = buildNamespace({
  system,
  entity: "QuestionGeneration",
} as const);

export const QuestionGenerationSchema = z.object({
  operationId: z.string(),
  info: planInfoSchema,
  action: z.object({
    key: z.string(),
    args: z.object({}).passthrough(),
  }),
});

const QuestionGenerationGuard = wrapSchema(QuestionGenerationSchema);

export const QuestionGenerationDef = buildSNSSQSDef({
  namespace: QuestionGenerationNS,
  prefix: "AmazonTextract-", // Textract isn't used anymore but keeping the name unchanged
  guard: QuestionGenerationGuard,
  sqsOnly: false,
  groupKey: undefined,
  idKey: undefined,
  maxAttempts: 1,
  visibilityTimeout: 600,
});
