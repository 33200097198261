import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { TeamItem } from "../../types";

const SectionTeamWrapper = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  border-radius: 4px 4px 0 0;
  padding: ${(props) => props.theme.baseUnit}rem;
  height: ${(props) => props.theme.baseUnit * 7}rem;

  .team-logo {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    width: ${(props) => props.theme.baseUnit * 5}rem;
    height: ${(props) => props.theme.baseUnit * 5}rem;
  }

  h3 {
    flex: 1;
    font-size: ${(props) => props.theme.fontSize.emphasis};
    font-weight: 500;
    margin: 0 ${(props) => props.theme.baseUnit}rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TeamInitials = styled.div`
  height: ${(props) => props.theme.baseUnit * 5}rem;
  width: ${(props) => props.theme.baseUnit * 5}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.baseUnit * 2}rem;
  border-radius: ${(props) => props.theme.baseUnit * 0.8}rem;
  user-select: none;
  background: radial-gradient(
    circle,
    rgba(223, 227, 236, 1) 100%,
    rgba(191, 199, 217, 1) 100%
  );
  overflow: hidden;
`;

export type SectionTeamProps = {
  teamName: TeamItem["name"];
  teamPicture: TeamItem["picture"];
};

export const TeamLabel = ({ teamName, teamPicture }: SectionTeamProps) => {
  const [isImageValid, setIsImageValid] = useState(!!teamPicture);

  const isValidImage = async (url: string): Promise<boolean> => {
    try {
      return await new Promise<boolean>((resolve) => {
        const img = new Image();
        img.src = url;

        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    } catch {
      return false;
    }
  };

  useEffect(() => {
    if (teamPicture) {
      isValidImage(teamPicture)
        .then(setIsImageValid)
        .catch(() => setIsImageValid(false));
    } else {
      setIsImageValid(false);
    }
  }, [teamPicture]);

  const getTeamInitials = (name: string) => {
    const words = name
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .split(/\s+/)
      .filter(Boolean);
    return words
      .slice(0, 3)
      .map((word) => word[0].toUpperCase())
      .join("");
  };

  return (
    <SectionTeamWrapper>
      {isImageValid ? (
        <div
          role="img"
          aria-label={`${teamName} Logo`}
          className="team-logo"
          style={{ backgroundImage: `url(${teamPicture})` }}
        />
      ) : (
        <TeamInitials data-testid="team-initials">
          {getTeamInitials(teamName)}
        </TeamInitials>
      )}
      <h3>{teamName}</h3>
    </SectionTeamWrapper>
  );
};
