import { useState } from "react";

import { AnalyticsProvider } from "@smart/bridge-intake-components-dom";
import { AnalyticsTool } from "@smart/bridge-types-basic";
import { Loading } from "@smart/itops-components-dom";
import { useAsync } from "@smart/itops-hooks-dom";
import { extractId } from "@smart/itops-types-basic";
import { inIframe } from "@smart/itops-utils-basic";

import { SubmissionLoadView } from "./load";
import {
  intakeApi,
  loadStoredSubmission,
  saveStoredSubmission,
} from "../../hooks";
import { NotFound } from "../not-found";

export type SubmissionCreateViewProps = {
  encodedParam?: string;
  slugId?: string;
  teamId?: string;
};

export const SubmissionCreateView = ({
  encodedParam,
  slugId,
  teamId,
}: SubmissionCreateViewProps) => {
  const createSubmission = intakeApi.createSubmission();
  const [submissionInfo, setSubmissionInfo] = useState<{
    id: string;
    slug: string;
    analyticsTools?: AnalyticsTool[];
  }>();

  useAsync(async () => {
    const { storedInfo, hasValidSubmission } = loadStoredSubmission({
      encodedParam,
      slugId,
      teamId,
    });

    if (hasValidSubmission) {
      setSubmissionInfo({
        slug: storedInfo.slug,
        id: storedInfo.id,
        analyticsTools: storedInfo.analyticsTools,
      });
      return;
    }

    const result = await createSubmission.post({
      body: { encodedParam, slugId, teamId },
    });

    if (result?.slug?.uri && result.submission?.uri) {
      const slug = extractId(result.slug.uri);
      const id = extractId(result.submission.uri);

      saveStoredSubmission(
        { encodedParam, slugId, teamId },
        {
          slug,
          id,
          analyticsTools: result.analyticsTools as AnalyticsTool[],
        },
      );
      setSubmissionInfo({
        slug,
        id,
        analyticsTools: result.analyticsTools as AnalyticsTool[],
      });
    }
  }, []);

  if (
    createSubmission.status === "error" ||
    (createSubmission.status === "success" &&
      (!createSubmission.result?.slug || !createSubmission.result?.submission))
  )
    return <NotFound />;

  if (submissionInfo)
    return (
      <AnalyticsProvider config={submissionInfo.analyticsTools}>
        <SubmissionLoadView {...submissionInfo} hideTeam={inIframe()} />
      </AnalyticsProvider>
    );

  return <Loading />;
};
