import { Modal } from "@smart/itops-ui-dom";

export type AutofillUnavailableModalProps = {
  open: boolean;
  onClose: () => void;
};

export const AutofillUnavailableModal = ({
  open,
  onClose,
}: AutofillUnavailableModalProps) => (
  <Modal
    open={open}
    header={{ text: "Autofill Assistant is unavailable" }}
    footer={{
      right: [
        {
          text: "Ok",
          variant: "save",
          onClick: onClose,
        },
      ],
    }}
  >
    <p>
      The Autofill Assistant has already been used and is therefore no longer
      available for this form.
    </p>
  </Modal>
);
