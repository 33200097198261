import { SettingNS, SettingSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const settingGuard = wrapSchema(SettingSchema);

export const SettingDef = buildTableDef(
  {
    namespace: SettingNS,
    guard: settingGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
  } as const,
  {
    byTeam: {
      indexName: "byTeam",
      keyFields: ["teamUri"],
      keySchema: { teamUri: "string" },
    },
  } as const,
);
