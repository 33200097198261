import { z } from "zod";

import {
  fieldLinkSchema,
  fieldTypeValue,
  meetingTypeSchema,
  paymentSchema,
} from "@smart/bridge-types-basic";

export const loadedFormSchema = z.object({
  uri: z.string(),
  active: z.boolean(),
  title: z.string(),
  response: z.string(),
  teamUri: z.string(),
  category: z.string(),
  aiFillSettings: z
    .object({
      allowAiFill: z.boolean().optional(),
      sectionTitle: z.string().optional(),
      fileUploadLabel: z.string().optional(),
      fileUploadDescription: z.string().optional(),
    })
    .optional(),
  sections: z.array(
    z.object({
      uri: z.string(),
      title: z.string(),
      description: z.string().optional(),
      order: z.string(),
      links: z.array(fieldLinkSchema).optional().nullable(),
    }),
  ),
  groups: z.array(
    z.object({
      uri: z.string(),
      sectionUri: z.string(),
      label: z.string().optional(),
      description: z.string().optional(),
      hint: z.string().optional(),
      order: z.string(),
      type: z.enum([
        "layout",
        "template",
        "custom",
        "layoutContact",
        "layoutRepeatable",
      ]),
      templateType: z.enum(["address", "contact", "person"]).optional(),
      links: z.array(fieldLinkSchema).optional().nullable(),
      layout: z
        .object({
          id: z.string(),
          providerId: z.string(),
          name: z.string(),
          parentId: z.string().optional(),
          parentName: z.string().optional(),
          parentProviderId: z.string().optional(),
        })
        .optional(),
      repeatable: z.boolean().optional(),
      repeatPrompt: z.string().optional(),
      minRepeat: z.number().optional(),
      maxRepeat: z.number().optional(),
    }),
  ),
  fields: z.array(
    z.object({
      uri: z.string(),
      sectionUri: z.string(),
      label: z.string(),
      description: z.string().optional(),
      hint: z.string(),
      order: z.string(),
      type: z.enum(fieldTypeValue),
      options: z.array(z.object({ value: z.string(), label: z.string() })),
      mandatory: z.boolean(),
      allowCustomResponse: z.boolean().optional().nullable(),
      links: z.array(fieldLinkSchema).optional().nullable(),
      allowCopyFromFieldUri: z.string().optional(),
      groupUri: z.string().optional(),
      availableStaffIds: z.array(z.string()).optional(),
      duration: z.number().optional(),
      availability: z
        .array(
          z.object({
            day: z.number(),
            fromTime: z.object({
              hour: z.number(),
              minute: z.number(),
            }),
            toTime: z.object({
              hour: z.number(),
              minute: z.number(),
            }),
            enabled: z.boolean(),
          }),
        )
        .optional(),
      timezone: z.string().optional(),
      bufferTime: z.number().optional(),
      minimumNotice: z.number().optional(),
      meetingType: meetingTypeSchema.optional(),
      payment: paymentSchema.optional(),
    }),
  ),
});

export const addressPredictionSchema = z.object({
  description: z.string(),
  place_id: z.string(),
  structured_formatting: z.object({
    main_text: z.string(),
    secondary_text: z.string(),
  }),
});

export const addressSchema = z.object({
  unit: z.string().optional(),
  unitLabel: z.string().optional(),
  streetNumber: z.string().optional(),
  streetName: z.string().optional(),
  streetType: z.string().optional(),
  suburb: z.string().optional(),
  state: z.string().optional(),
  postalCode: z.string().optional(),
  country: z.string().optional(),
  locality: z.string().optional(),
  city: z.string().optional(),
  county: z.string().optional(),
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
  formattedAddress: z.string().optional(),
});

export type LoadedForm = z.infer<typeof loadedFormSchema>;
