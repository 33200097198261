import styled from "@emotion/styled";

import { Displayer } from "@smart/itops-editor-dom";
import { specialChars } from "@smart/itops-utils-basic";

import { GroupItem, fieldFallbackLabel } from "../../types";
import { Hint } from "../field-components";

const GroupHeadingWrapper = styled.legend`
  font-size: ${(props) => props.theme.fontSize.emphasis};
  margin-left: ${(props) => props.theme.baseUnit * -0.6}rem;
  padding: 0 ${(props) => props.theme.baseUnit}rem 0;
`;

export type GroupHeadingProps = {
  group: GroupItem;
  index: number;
};

export const GroupHeading = ({
  group: { label, hint, repeatable },
  index,
}: GroupHeadingProps) => (
  <GroupHeadingWrapper>
    <Displayer
      paragraphTag="span"
      value={label || fieldFallbackLabel.group}
      hintElement={
        <>
          {repeatable && (
            <span>
              {specialChars.nbsp}
              {index + 1}
            </span>
          )}
          {hint && <Hint content={hint} />}
        </>
      }
    />
  </GroupHeadingWrapper>
);
