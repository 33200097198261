import {
  UserActivityStateNS,
  UserActivityStateSchema,
} from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const userActivityStateGuard = wrapSchema(UserActivityStateSchema);

export const UserActivityStateDef = buildTableDef(
  {
    namespace: UserActivityStateNS,
    guard: userActivityStateGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
  } as const,
  {} as const,
);
