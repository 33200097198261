import {
  FormMatterTypeNS,
  FormMatterTypeSchema,
} from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const formMatterTypeGuard = wrapSchema(FormMatterTypeSchema);

export const FormMatterTypeDef = buildTableDef(
  {
    namespace: FormMatterTypeNS,
    guard: formMatterTypeGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
    ttlField: "deletedAtSec",
  } as const,
  {
    byForm: {
      indexName: "byForm",
      keyFields: ["formUri", "id"],
      keySchema: { formUri: "string", id: "string" },
    },
    byMatterType: {
      indexName: "byMatterType",
      keyFields: ["id", "teamUri"],
      keySchema: { id: "string", teamUri: "string" },
    },
    byCategory: {
      indexName: "byCategory",
      keyFields: ["category", "teamUri"],
      keySchema: { category: "string", teamUri: "string" },
    },
    byOwner: {
      indexName: "byOwner",
      keyFields: ["teamUri", "formUri"],
      keySchema: { teamUri: "string", formUri: "string" },
    },
  } as const,
);
