import {
  tableKeys,
  transportKeys,
  bucketKeys,
} from "@smart/bridge-resources-basic";
import { buildPermissionsDef } from "@smart/itops-constructs-basic";

import { apiGateway } from "./api-gateway";

export const permissions = buildPermissionsDef(
  apiGateway.lambdaKeys,
  tableKeys,
  transportKeys,
  bucketKeys,
  [],
  {
    createSubmission: {
      dynamo: { default: "READ" },
      sns: { default: null, Plan: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    load: {
      dynamo: { default: "READ" },
      sns: { default: null, Plan: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    submit: {
      dynamo: { default: "READ" },
      sns: { default: null, Plan: "PUBLISH", QuestionGeneration: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    searchAddress: {
      dynamo: { default: null },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
    getAddress: {
      dynamo: { default: null },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
    uploadFile: {
      dynamo: { default: "READ" },
      sns: { default: null },
      s3: { default: null, IntakeUploadedFiles: "WRITE" },
      additionalResources: { default: null },
    },
    recaptcha: {
      dynamo: { default: null },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
  },
);
