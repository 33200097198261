import { SlugNS, SlugSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const slugGuard = wrapSchema(SlugSchema);

export const SlugDef = buildTableDef(
  {
    namespace: SlugNS,
    guard: slugGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
    ttlField: "deletedAtSec",
  } as const,
  {
    byForm: {
      indexName: "byForm",
      keyFields: ["formUri", "updatedAt"],
      keySchema: { formUri: "string", updatedAt: "string" },
    },
  } as const,
);
