import { system } from "@smart/bridge-types-basic";
import { buildZoneDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

export const zone = buildZoneDef({
  namespace: buildNamespace({ system, entity: "matter-intake" }),
  domains: {
    local: {
      aus: "smartsb.dev",
      nova: "smartsb.dev",
      lon: "smartsb.dev",
    },
    dev: {
      aus: "dev-matter-intake.com.au",
      nova: "dev-matter-intake.com",
      lon: "dev-matter-intake.co.uk",
    },
    staging: {
      aus: "stg-matter-intake.com.au",
      nova: "stg-matter-intake.com",
      lon: "stg-matter-intake.co.uk",
    },
    live: {
      aus: "matter-intake.com.au",
      nova: "matter-intake.com",
      lon: "matter-intake.co.uk",
    },
  },
});
