import { FieldGroup, TextInput } from "@smart/itops-ui-dom";

import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

export const EmailField = ({
  field,
  index,
  innerRef,
  value,
  error,
  loading,
  disabled,
  onChange,
  onBlur,
}: FieldProps<HTMLInputElement>) => {
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <FieldGroup
        id={id}
        error={error}
        hint={field.hint ?? undefined}
        label={<Label {...field} index={index} />}
      >
        <TextInput
          ref={innerRef}
          id={id}
          name={id}
          error={!!error}
          value={value ?? ""}
          aria-invalid={!!error}
          aria-errormessage={errorId ?? undefined}
          type="text"
          inputMode="email"
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          formNoValidate
          size="base"
        />
      </FieldGroup>
    </FieldWrapper>
  );
};
