import styled from "@emotion/styled";
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useRef, useState } from "react";

import { Displayer } from "@smart/itops-editor-dom";
import { Icon } from "@smart/itops-icons-dom";

const HintButton = styled.button`
  background: none;
  border: 0;
  display: inline-block;
  margin: 0 0 0 ${(props) => props.theme.baseUnit * 0.6}rem;
  padding: 0;

  svg {
    display: block;
  }
`;

const HintContent = styled.div`
  background: ${(props) => props.theme.palette.background.highlight};
  border-radius: 5px;
  max-width: min(25rem, 96%);
  padding: ${(props) => props.theme.baseUnit * 0.2}rem
    ${(props) => props.theme.baseUnit * 0.6}rem;
  z-index: 1;

  svg {
    fill: ${(props) => props.theme.palette.background.highlight};
  }

  p {
    margin: ${(props) => props.theme.baseUnit * 0.4}rem 0;
  }
`;

export type HintProps = {
  content: string;
};

export const Hint = ({ content }: HintProps) => {
  const arrowRef = useRef(null);
  const [open, onOpenChange] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange,
    placement: "right",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      shift({ padding: { top: 5, bottom: 5, left: 22, right: 5 } }),
      flip({ fallbackPlacements: ["bottom", "top", "left"] }),
      arrow({ element: arrowRef }),
    ],
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const { getFloatingProps, getReferenceProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      <HintButton
        ref={refs.setReference}
        {...getReferenceProps()}
        type="button"
        aria-label="More Information"
      >
        <Icon name="questionCircle" size={18} />
      </HintButton>
      {open && (
        <HintContent
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <FloatingArrow ref={arrowRef} context={context} />
          <Displayer value={content} />
        </HintContent>
      )}
    </>
  );
};
