import { z } from "zod";

import { planInfoSchema } from "@smart/bridge-domains-basic";
import { system } from "@smart/bridge-types-basic";
import { buildSNSSQSDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const PDFProcessingNS = buildNamespace({
  system,
  entity: "PDFProcessing",
} as const);

export const PDFProcessingSchema = z.object({
  operationId: z.string(),
  info: planInfoSchema,
  action: z.object({
    key: z.string(),
    args: z.object({}).passthrough(),
  }),
});

const PDFProcessingGuard = wrapSchema(PDFProcessingSchema);

export const PDFProcessingDef = buildSNSSQSDef({
  namespace: PDFProcessingNS,
  guard: PDFProcessingGuard,
  sqsOnly: false,
  groupKey: undefined,
  idKey: undefined,
  maxAttempts: 1,
  visibilityTimeout: 300,
});
