import { useCallback, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { Loading } from "@smart/itops-components-dom";

import { IntakePrintContent, IntakePrintContentProps } from "./print-content";
import { IntakePrintUnavailable } from "./print-unavailable";
import { printTitle } from "../../types";

export type IntakePrintProps = {
  disabled?: boolean;
  unavailable?: string;
  content?: IntakePrintContentProps;
};

export const useIntakePrint = (props?: IntakePrintProps) => {
  const canPrint = !props?.unavailable && !!props?.content;

  const content = props?.content || {
    formTitle: undefined,
    sections: [],
    groups: [],
    fields: [],
    responses: {},
  };

  const [showModal, setShowModal] = useState(false);
  const [printing, setPrinting] = useState(false);

  const printContentRef = useRef(null);
  const printContent = useCallback(
    () => printContentRef.current,
    [printContentRef.current],
  );

  const handlePrint = useReactToPrint({
    content: printContent,
    documentTitle: content.formTitle || printTitle,
    removeAfterPrint: false,
    onBeforeGetContent: () =>
      new Promise((resolve) => {
        setPrinting(true);
        resolve(undefined);
      }),
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return {
    onPrint: () => {
      if (props?.disabled) return;
      if (canPrint) {
        handlePrint();
      } else {
        setShowModal(true);
      }
    },
    elements: (
      <>
        {canPrint ? (
          <IntakePrintContent ref={printContentRef} {...content} />
        ) : (
          <IntakePrintUnavailable
            text={props?.unavailable || ""}
            open={showModal}
            onClose={() => setShowModal(false)}
          />
        )}
        {printing && <Loading />}
      </>
    ),
  };
};
