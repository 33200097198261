import styled from "@emotion/styled";

import { Icon } from "@smart/itops-icons-dom";
import { Button } from "@smart/itops-ui-dom";

import { ToastContent } from "../../types";

const ToastWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  .content {
    position: absolute;
    display: flex;
    flex-grow: 1;
    top: 0;
    z-index: 10;

    margin: 0rem auto;
    padding: 1rem 3rem;
    max-width: ${(props) => props.theme.breakPoints.readable}px;
    width: 100%;

    background-color: ${(props) => props.theme.scheme.green.r80};
    border-radius: 0.5rem;
    box-shadow:
      0px 0px 1px 0px rgb(0, 0, 0, 0.32),
      0px 4px 8px 0px rgb(0, 0, 0, 0.24);
    font-size: ${(props) => props.theme.fontSize.base};

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;

    .info {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    button {
      padding: 0;
      background-color: transparent;
    }

    .toast-icon {
      color: white;
      background-color: black;
      border-radius: 50%;
      padding: 3px;
    }
  }
`;

export type SectionToastProps = {
  content?: ToastContent;
  onClose?: () => void;
  timeoutInSec?: number;
};

export const SectionToast = ({
  content,
  onClose,
  timeoutInSec,
}: SectionToastProps) => {
  if (!content) return null;

  if (timeoutInSec && onClose) {
    setTimeout(() => onClose(), timeoutInSec * 1000);
  }

  return (
    <ToastWrapper>
      <div className="content">
        <div className="info">
          <Icon
            library="lucide"
            name={content.type === "success" ? "Check" : "AlertCircle"}
            className="toast-icon"
            size={18}
          />
          {content.text}
        </div>
        {onClose && (
          <Button icon={{ library: "lucide", name: "X" }} onClick={onClose} />
        )}
      </div>
    </ToastWrapper>
  );
};
