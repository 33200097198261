import { useState } from "react";

type FileUploadState = {
  [id: string]: {
    uploading: boolean;
    progress: number;
  }[];
};

export function useFileUpload() {
  const [fileUploadStates, setFileUploadStates] = useState<FileUploadState>({});

  const initialise = (id: string, maxNumOfFiles: number) => {
    setFileUploadStates((prevStates) => {
      if (prevStates[id]) {
        return prevStates;
      }

      const newUploadState = {
        ...prevStates,
        [id]: Array.from({ length: maxNumOfFiles }, () => ({
          uploading: false,
          progress: 0,
        })),
      };
      return newUploadState;
    });
  };

  const onProgress = (id: string, fileIndex: number, newProgress: number) => {
    setFileUploadStates((prevStates) => {
      if (!prevStates[id]) {
        return prevStates;
      }

      const newUploadState = {
        ...prevStates,
        [id]: prevStates[id].map((prevState, index) =>
          index === fileIndex
            ? { ...prevState, progress: newProgress }
            : prevState,
        ),
      };

      return newUploadState;
    });
  };

  const getUploadStates = (id: string) => fileUploadStates[id] || [];

  const onUploadStatusChange = (
    id: string,
    fileIndex: number,
    status: boolean,
  ) => {
    setFileUploadStates((prevStates) => {
      if (!prevStates[id]) {
        return prevStates;
      }

      const newUploadState = {
        ...prevStates,
        [id]: prevStates[id].map((prevState, index) =>
          index === fileIndex ? { ...prevState, uploading: status } : prevState,
        ),
      };

      return newUploadState;
    });
  };

  return { initialise, onProgress, onUploadStatusChange, getUploadStates };
}
