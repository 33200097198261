import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { loadEnv } from "@smart/bridge-env-dom";

declare const SF_IS_LOCAL: string;
declare const SF_FORCE_TRACK: string;

export const skipReCaptcha = () => !!SF_IS_LOCAL && !SF_FORCE_TRACK;

export const useReCaptcha = () => {
  const siteKey = loadEnv("GoogleCaptchaKey");

  if (skipReCaptcha()) return { executeRecaptcha: undefined, siteKey };

  const { executeRecaptcha } = useGoogleReCaptcha();

  return {
    executeRecaptcha,
    siteKey,
  };
};
