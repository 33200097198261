import { useEffect } from "react";

import { track } from "@smart/bridge-metrics-dom";
import { Overlay } from "@smart/itops-components-dom";

export const NotFound = () => {
  useEffect(() => {
    track("Invalid Link", { link: window.location.pathname });
  });

  return (
    <Overlay
      icon="form"
      heading="Not Found"
      subHeading="Unfortunately, the page you're looking for could not be found. To find out more, please contact the sender."
      level="DEFAULT"
    />
  );
};
