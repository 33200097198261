import styled from "@emotion/styled";

import { EditableInput } from "@smart/itops-ui-dom/src/components/editable-text/editable-input";

import { Indicator } from "./indicator";
import { fieldName } from "../../hooks";
import { FieldComponentType, fieldFallbackLabel, FieldItem } from "../../types";

export type LabelProps = Pick<
  FieldItem,
  "uri" | "label" | "hint" | "description" | "mandatory" | "type" | "layout"
> & { index?: number; hideLabel?: boolean };

const Question = styled.div`
  display: flex;
  align-items: center;
`;

const LabelWrapper = styled.label`
  .editable {
    span {
      margin: 0;
    }
  }
`;

export const Label = ({
  uri,
  label,
  description,
  mandatory,
  index,
  type,
  layout,
  hideLabel,
}: LabelProps) => {
  const fallback = layout
    ? fieldFallbackLabel.mapped
    : fieldFallbackLabel[type as FieldComponentType];

  return (
    <LabelWrapper htmlFor={fieldName({ field: { uri }, index })}>
      {!hideLabel && (
        <Question>
          <EditableInput
            text={label || fallback}
            fontSize="emphasis"
            isPreview
            disabled
          />
          {mandatory && <Indicator size="subHeading" />}
        </Question>
      )}
      {description && (
        <EditableInput
          text={description}
          fontSize="base"
          dataTestId="description"
          isPreview
          disabled
        />
      )}
    </LabelWrapper>
  );
};
