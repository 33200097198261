import { SubmissionNS, SubmissionSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const submissionGuard = wrapSchema(SubmissionSchema);

export const SubmissionDef = buildTableDef(
  {
    namespace: SubmissionNS,
    guard: submissionGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
  } as const,
  {
    byForm: {
      indexName: "byForm",
      keyFields: ["formUri", "updatedAt"],
      keySchema: { formUri: "string", updatedAt: "string" },
    },
    byTeam: {
      indexName: "byTeam",
      keyFields: ["teamUri", "updatedAt"],
      keySchema: { teamUri: "string", updatedAt: "string" },
    },
    byTeamCreated: {
      indexName: "byTeamCreated",
      keyFields: ["teamUri", "createdAt"],
      keySchema: { teamUri: "string", createdAt: "string" },
    },
    byMatter: {
      indexName: "byMatter",
      keyFields: ["matterId", "formUri"],
      keySchema: { matterId: "string", formUri: "string" },
    },
    byMatterStatus: {
      indexName: "byMatterStatus",
      keyFields: ["matterId", "status"],
      keySchema: { matterId: "string", status: "string" },
    },
    byRecipientEmail: {
      indexName: "byRecipientEmail",
      keyFields: ["recipientEmail", "teamUri"],
      keySchema: { recipientEmail: "string", teamUri: "string" },
    },
  } as const,
);
