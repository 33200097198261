import { useEffect, useState } from "react";

import { FieldItem, SectionItem } from "../types";

export type SectionVisitOptions = {
  selected?: SectionItem;
  visibleSections: SectionItem[];
  visibleFields: FieldItem[];
  responses: Record<string, any>;
  lastUpdatedSectionUri?: string;
};

export type SectionVisit = {
  isUpdating: boolean;
  isAllVisited: boolean;
  setSectionVisited: (section: SectionItem) => void;
  setSectionVisiting: (section: SectionItem) => void;
  isSectionVisiting: (section: SectionItem) => boolean;
  isSectionVisited: (section: SectionItem) => boolean;
  isNavigatable: (section: SectionItem) => boolean;
  reset: () => void;
};

export const useSectionVisit = ({
  selected,
  visibleSections,
  visibleFields,
  lastUpdatedSectionUri,
  responses,
}: SectionVisitOptions): SectionVisit => {
  const [currentVisibleFields, setCurrentVisibleFields] =
    useState<FieldItem[]>(visibleFields);
  const [visitedSections, setVisitedSections] = useState<SectionItem[]>(() => {
    const lastUpdatedSectionIndex = lastUpdatedSectionUri
      ? visibleSections.findIndex((s) => s.uri === lastUpdatedSectionUri)
      : -1;

    if (lastUpdatedSectionIndex !== -1) {
      return visibleSections.slice(0, lastUpdatedSectionIndex + 1);
    }

    return [];
  });
  const [visitingSections, setVisitingSections] = useState<SectionItem[]>([]);
  const [lastUpdatedSection, setLastUpdatedSection] = useState<SectionItem>();

  const isSectionVisiting = (section: SectionItem): boolean =>
    !!visitingSections.find((s) => s.uri === section.uri);

  const isSectionVisited = (section: SectionItem): boolean =>
    !!visitedSections.find((s) => s.uri === section.uri);

  const setSectionVisited = (section: SectionItem) => {
    if (!isSectionVisited(section)) {
      setVisitedSections([...visitedSections, section]);
      setVisitingSections(
        visitingSections.filter((s) => s.uri !== section.uri),
      );
    }
  };

  const setSectionVisiting = (section: SectionItem) => {
    if (!isSectionVisited(section) && !isSectionVisiting(section))
      setVisitingSections([...visitingSections, section]);
  };

  const willFieldsVisible = (section: SectionItem): boolean => {
    const currentVisibleSectionFields = currentVisibleFields.filter(
      (f) => f.sectionUri === section.uri,
    );
    const willBeVisibleSectionFields = visibleFields.filter(
      (f) => f.sectionUri === section.uri,
    );

    if (willBeVisibleSectionFields.length > currentVisibleSectionFields.length)
      return true;

    return !!willBeVisibleSectionFields.find(
      (willBe) =>
        !currentVisibleSectionFields.find((curr) => curr.uri === willBe.uri),
    );
  };

  useEffect(() => {
    const firstNotVisitedSectionIndex = visibleSections.findIndex(
      (visibleSection) =>
        !isSectionVisited(visibleSection) || willFieldsVisible(visibleSection),
    );

    // Sections after the first unvisited section should NOT be visited or visiting as well
    const canVisitSections =
      firstNotVisitedSectionIndex !== -1
        ? visibleSections.slice(0, firstNotVisitedSectionIndex + 1)
        : visibleSections;

    setVisitedSections(
      visitedSections.filter((s) =>
        canVisitSections.find((canVisit) => canVisit.uri === s.uri),
      ),
    );

    setVisitingSections(
      visitingSections.filter((s) =>
        canVisitSections.find((canVisit) => canVisit.uri === s.uri),
      ),
    );

    if (selected) setSectionVisiting(selected);
    setLastUpdatedSection(selected);
    setCurrentVisibleFields(visibleFields);
  }, [responses]);

  const isNavigatable = (section: SectionItem): boolean =>
    isSectionVisiting(section) || isSectionVisited(section);

  const isUpdating =
    !!selected &&
    isSectionVisiting(selected) &&
    lastUpdatedSection?.uri === selected.uri;

  const reset = () => {
    const lastUpdatedSectionIndex = lastUpdatedSectionUri
      ? visibleSections.findIndex((s) => s.uri === lastUpdatedSectionUri)
      : -1;

    setVisitingSections([]);
    setVisitedSections(
      lastUpdatedSectionIndex !== -1
        ? visibleSections.slice(0, lastUpdatedSectionIndex + 1)
        : [],
    );
  };

  return {
    isUpdating,
    isAllVisited: visibleSections.length === visitedSections.length,
    setSectionVisiting,
    setSectionVisited,
    isNavigatable,
    isSectionVisiting,
    isSectionVisited,
    reset,
  };
};
