import { ReactNode } from "react";

export type SectionFormProps = {
  onSubmit?: () => void;
  onReset?: () => void;
  isForm: boolean;
  children: ReactNode;
};

export const SectionForm = ({
  onSubmit,
  onReset,
  isForm,
  children,
}: SectionFormProps) =>
  isForm ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (onSubmit) onSubmit();
      }}
      onReset={(e) => {
        e.preventDefault();
        if (onReset) onReset();
      }}
    >
      {children}
    </form>
  ) : (
    <div className="section-form-alternative">{children}</div>
  );
