import { z } from "zod";

import {
  activityEventDataTypeValue,
  activityTypeValue,
  system,
} from "@smart/bridge-types-basic";
import { buildSNSSQSDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const ActivityEventNS = buildNamespace({
  system,
  entity: "ActivityEvent",
} as const);

export const ActivityEventSchema = z.object({
  operationId: z.string(),
  type: z.enum(activityEventDataTypeValue),
  activity: z.enum(activityTypeValue),
  matterId: z.string(),
  submissionId: z.string().optional(),
  formId: z.string().optional(),
  fieldId: z.string().optional(),
  userId: z.string(),
  createdAt: z.string(),
});

const activityEventGuard = wrapSchema(ActivityEventSchema);

export const ActivityEventDef = buildSNSSQSDef({
  namespace: ActivityEventNS,
  guard: activityEventGuard,
  maxAttempts: 1,
  sqsOnly: false,
  groupKey: "userId",
  idKey: "operationId",
});
