import { LookupOptions } from "@smart/bridge-intake-components-dom";
import { uploadFile as upload } from "@smart/itops-ui-dom";

import { intakeApi } from "./api";

export const useFileLookup = ({
  formUri,
  submissionUri,
  teamUri,
}: {
  formUri: string;
  submissionUri: string;
  teamUri: string;
}): LookupOptions["fileLookup"] => {
  const uploadFile = intakeApi.uploadFile();

  return {
    upload,
    load: ({ fieldUri, fileNames }) =>
      uploadFile.post({
        body: {
          fieldUri,
          fileNames,
          formUri,
          submissionUri,
          teamUri,
        },
      }),
  };
};
