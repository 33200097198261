import styled from "@emotion/styled";
import { MouseEvent } from "react";

import { checkHasResponse } from "@smart/bridge-types-basic";
import { Loading } from "@smart/itops-components-dom";

import {
  Page,
  Section,
  SectionButtons,
  PageWrapper,
  SectionHeadingWrapper,
  SectionForm,
} from "./section-components";
import { ErrorSummary } from "./shared-components/error-summary";
import {
  SummaryField,
  SummaryGroup,
  SummarySection,
} from "./summary-components";
import { renderOrderedItems, requiredMessage } from "../hooks";
import {
  AutoFillStatus,
  OrderedSection,
  SectionItem,
  summaryTitle,
  TeamItem,
} from "../types";

const SummaryPage = styled(Page)`
  position: relative;
`;

const SummaryHeading = styled(SectionHeadingWrapper)`
  h1 {
    font-weight: bold;
  }
  border-bottom: 1px solid
    ${(props) => props.theme.palette.background.highlight};
  padding-bottom: ${(props) => props.theme.baseUnit * 3}rem;
  margin-bottom: ${(props) => props.theme.baseUnit * 3}rem;
`;

export type IntakeSummaryProps = {
  team: TeamItem;
  visibleSections: OrderedSection[];
  responses: Record<string, any>;
  isLoadingDBResponses: boolean;
  sidebar: boolean;
  onSelect?: (section: SectionItem) => void;
  onSubmitButton?: (e: MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: () => void;
  onReset?: () => void;
  submitError?: string;
  isForm?: boolean;
  hasPayment: boolean;
  hidePreviewErrors?: boolean;
  autoFillingStatus?: AutoFillStatus;
};

const noop = () => {};

export const IntakeSummary = ({
  team,
  visibleSections,
  responses,
  isLoadingDBResponses,
  sidebar,
  onSelect,
  onSubmitButton,
  onSubmit,
  onReset,
  hasPayment,
  submitError,
  isForm = true,
  hidePreviewErrors,
  autoFillingStatus,
}: IntakeSummaryProps) => {
  const missingRequired = visibleSections
    .map(({ orderedItems }) =>
      orderedItems.map((item) =>
        item.type === "group" ? item.fields : [item.field],
      ),
    )
    .flat(2)
    .filter(
      (field) =>
        field.mandatory &&
        !checkHasResponse(
          field.type,
          field.groupUri ? responses[field.uri]?.[0] : responses[field.uri],
        ),
    );

  return (
    <PageWrapper sidebar={sidebar}>
      <SummaryPage>
        {isLoadingDBResponses && <Loading />}
        <Section>
          <SectionForm onSubmit={onSubmit} onReset={onReset} isForm={isForm}>
            <SectionButtons
              isForm={isForm}
              team={team}
              hasError={submitError}
              hasPayment={hasPayment}
              onPrevious={
                onSelect
                  ? () =>
                      onSelect(
                        visibleSections[visibleSections.length - 1].section,
                      )
                  : undefined
              }
              onSubmit={onSubmitButton || noop}
              disabled={{
                submit:
                  (!hidePreviewErrors && !!missingRequired.length) ||
                  autoFillingStatus === "filling",
              }}
            >
              <SummaryHeading>
                <h1>{summaryTitle}</h1>
                <p>Please review your responses</p>
              </SummaryHeading>
              {!hidePreviewErrors && !!missingRequired.length && (
                <ErrorSummary
                  errors={missingRequired.map((field) => ({
                    name: field.uri,
                    label: field.label,
                    error: requiredMessage,
                    onClick: () => {
                      const section = visibleSections.find(
                        (s) => s.section.uri === field.sectionUri,
                      );
                      if (section) {
                        onSelect?.({
                          ...section.section,
                          validateOnDisplay: true,
                        });
                      }
                    },
                  }))}
                />
              )}
              {visibleSections.map(({ orderedItems, section }) => (
                <SummarySection
                  key={section.uri}
                  section={section}
                  onEdit={onSelect ? () => onSelect(section) : undefined}
                >
                  {renderOrderedItems({
                    orderedItems,
                    responses,
                    renderGroup: (props) => <SummaryGroup {...props} />,
                    renderField: (props) => <SummaryField {...props} />,
                  })}
                </SummarySection>
              ))}
            </SectionButtons>
          </SectionForm>
        </Section>
      </SummaryPage>
    </PageWrapper>
  );
};
