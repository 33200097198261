import { SNSSQSTypes } from "@smart/itops-constructs-basic";
import { namespacedList } from "@smart/itops-types-basic";
import { keysOf } from "@smart/itops-utils-basic";

import { ActivityEventDef } from "./activity-event";
import { ActivityHeartbeatDef } from "./activity-heartbeat";
import { BackoffDef } from "./backoff";
import { MigrateDef } from "./migrate";
import { PDFProcessingDef } from "./pdf-processing";
import { PlanDef } from "./plan";
import { QuestionGenerationDef } from "./question-generation";
import { RequeueDef } from "./requeue";
import { SmokeballIntegrationDef } from "./smokeball-integration";

export const transports = namespacedList({
  Backoff: BackoffDef,
  Plan: PlanDef,
  Requeue: RequeueDef,
  Migrate: MigrateDef,
  SmokeballIntegration: SmokeballIntegrationDef,
  PDFProcessing: PDFProcessingDef,
  ActivityEvent: ActivityEventDef,
  ActivityHeartbeat: ActivityHeartbeatDef,
  QuestionGeneration: QuestionGenerationDef,
});

export const transportKeys = keysOf(transports);

export type Transports = typeof transports;

export type TransportKey = keyof Transports;

export type TransportTypes = SNSSQSTypes<Transports>;

export type TBackoff = TransportTypes["Backoff"];

export type TPlan = TransportTypes["Plan"];

export type TRequeue = TransportTypes["Requeue"];

export type TMigrate = TransportTypes["Migrate"];

export type TSmokeballIntegration = TransportTypes["SmokeballIntegration"];

export type TPDFProcessing = TransportTypes["PDFProcessing"];

export type TActivityEvent = TransportTypes["ActivityEvent"];

export type TActivityHeartbeat = TransportTypes["ActivityHeartbeat"];

export type TQuestionGeneration = TransportTypes["QuestionGeneration"];
