import styled from "@emotion/styled";

import {
  formatValue,
  PaymentResponse,
  UploadedFile,
} from "@smart/bridge-types-basic";
import { Displayer } from "@smart/itops-editor-dom";
import {
  entriesOf,
  localTimeZoneCode,
  specialChars,
} from "@smart/itops-utils-basic";

import { FieldComponentType, fieldFallbackLabel, FieldItem } from "../../types";
import { displayAppointmentValues } from "../../utils";
import { Indicator } from "../field-components";

const SummaryFieldWrapper = styled.div`
  h4 {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }

  h3 {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }

  p {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }
`;

export type SummaryFieldProps = {
  field: FieldItem;
  value: any;
};

export const SummaryField = ({
  field: { label, mandatory, type, options, description },
  value,
}: SummaryFieldProps) => {
  const displayFieldValue = () => {
    if (type === "info") return null;

    if (type === "file" && Array.isArray(value)) {
      return value?.length
        ? value.map((file: UploadedFile) => (
            <p key={file.downloadUrl}>{file.fileName}</p>
          ))
        : specialChars.enDash;
    }

    if (type === "appointment") {
      const date = displayAppointmentValues({
        startTime: value?.startTime,
        endTime: value?.endTime,
        timezone: value?.timezone,
      });
      return (
        <div>
          <p data-testid="booking-message">
            {value && value.status !== "invalid" && value.bookedStaff?.length
              ? `With ${[
                  value.bookedStaff[0].firstName,
                  value.bookedStaff[0].middleName,
                  value.bookedStaff[0].lastName,
                ]
                  .filter(Boolean)
                  .join(
                    " ",
                  )} at ${date.time} on ${date.day} (${value?.timezone || localTimeZoneCode})`
              : specialChars.enDash}
          </p>
        </div>
      );
    }

    if (type === "payment") {
      const paymentValue = value as PaymentResponse | undefined;
      if (!paymentValue?.chargeRequest) return specialChars.enDash;

      const charges = entriesOf(paymentValue?.charges || {});

      if (!charges.length)
        return (
          <p>Payment Pending - {paymentValue.chargeRequest.description}</p>
        );

      return charges.map(([key, charge]) => {
        let status = "Payment Successful";
        let message = paymentValue.chargeRequest?.description;

        if (charge.status === "FAILED") {
          status = "Payment Failed";
          message =
            charge.smokeballResponse?.failure?.message ||
            "An unexpected error occurred during transaction processing";
        }

        return (
          <p key={key}>
            {status} - {message}
          </p>
        );
      });
    }

    return (
      <Displayer
        paragraphTag="p"
        value={formatValue(value, type, options)}
        fallback={specialChars.enDash}
      />
    );
  };

  return (
    <SummaryFieldWrapper>
      <Displayer
        paragraphTag="h4"
        value={label}
        fallback={fieldFallbackLabel[type as FieldComponentType]}
        hintElement={mandatory && <Indicator />}
      />
      {description && <Displayer paragraphTag="h3" value={description} />}
      {displayFieldValue()}
    </SummaryFieldWrapper>
  );
};
