import styled from "@emotion/styled";
import { ReactNode } from "react";

import { Displayer } from "@smart/itops-editor-dom";
import { Icon } from "@smart/itops-icons-dom";
import { Button } from "@smart/itops-ui-dom";

import { Group, GroupHeading } from "./group-components";
import { GroupItem } from "../types";

const DescriptionWrapper = styled.div`
  p {
    margin-top: 0;
    font-size: ${(props) => props.theme.fontSize.base};
  }
`;

export type IntakeGroupProps = {
  group: GroupItem;
  index: number;
  onAdd?: () => void;
  onRemove?: () => void;
  children?: ReactNode;
};

const RemoveGroupButtonWrapper = styled.div`
  top: -3.2rem;
  display: flex;
  position: relative;
  justify-content: flex-end;
  height: 0;

  button {
    padding: 1rem;
    color: ${(props) => props.theme.scheme.red.r100};
  }
`;

const AddGroupButtonWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSize.small};
  top: 2.6rem;
  display: flex;
  position: relative;
  justify-content: flex-start;

  button {
    padding: 0.4rem 1.4rem;
  }
`;

const RemoveGroupButton = ({ onRemove }: { onRemove: () => void }) => (
  <RemoveGroupButtonWrapper>
    <Button
      onClick={onRemove}
      dataTestId="remove-button"
      icon={{ library: "lucide", name: "Trash2" }}
    />
  </RemoveGroupButtonWrapper>
);

const AddGroupButton = ({ onAdd }: { onAdd: () => void }) => (
  <AddGroupButtonWrapper>
    <Button
      onClick={onAdd}
      text="Add"
      variant="cancel"
      dataTestId="add-button"
      left={<Icon name="Plus" library="lucide" size={15} />}
    />
  </AddGroupButtonWrapper>
);

const Gap = styled.div`
  margin-bottom: -0.3rem;
`;

export const IntakeGroup = ({
  group,
  index,
  onAdd,
  onRemove,
  children,
}: IntakeGroupProps) => (
  <>
    <Group>
      <GroupHeading group={group} index={index} />
      {onRemove && <RemoveGroupButton onRemove={onRemove} />}
      <DescriptionWrapper>
        <Displayer paragraphTag="p" value={group.description} />
      </DescriptionWrapper>
      {children}
      {onAdd && <AddGroupButton onAdd={onAdd} />}
    </Group>
    {onAdd && <Gap />}
  </>
);
