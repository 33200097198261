import {
  SubmissionLinkHashNS,
  SubmissionLinkHashSchema,
} from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const submissionLinkHashGuard = wrapSchema(SubmissionLinkHashSchema);

export const SubmissionLinkHashDef = buildTableDef(
  {
    namespace: SubmissionLinkHashNS,
    guard: submissionLinkHashGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
    ttlField: "deletedAtSec",
  } as const,
  {
    byTeam: {
      indexName: "byTeam",
      keyFields: ["teamUri"],
      keySchema: { teamUri: "string" },
    },
  } as const,
);
