import {
  Identify,
  identify as amplitudeIdentify,
} from "@amplitude/analytics-browser";

import { loadEnv } from "@smart/bridge-env-dom";
import { getBuildVersion } from "@smart/itops-utils-basic";

import { skipTracking } from "./util";

export type IdentifyProps = {
  source?: string;
  team?: { uri: string; name: string };
  user?: { uri: string; email: string };
};

declare global {
  interface Window {
    globalDataLayer: any[];
  }
}

export const identifyTrackingUser = ({ source, team, user }: IdentifyProps) => {
  const { version, build } = getBuildVersion();
  const env = loadEnv("Env");
  const region = loadEnv("Region");
  window.globalDataLayer?.push({
    event: "identify",
    version,
    build,
    env,
    region,
    source,
    teamUri: team?.uri,
    teamName: team?.name,
  });

  if (skipTracking()) return;

  const id = new Identify();

  if (source) id.set("Source", source);

  if (team) {
    id.set("Team Id", team.uri);
    id.set("Team Name", team.name);
  }

  if (user) {
    id.set("User Id", user.uri);
    id.set("User Email", user.email);
  }

  amplitudeIdentify(id);
};
