import { init as amplitudeInit } from "@amplitude/analytics-browser";

import { loadEnv } from "@smart/bridge-env-dom";
import { getBuildVersion } from "@smart/itops-utils-basic";

import { skipTracking } from "./util";

export const initMetrics = () => {
  if (skipTracking()) return;

  amplitudeInit(loadEnv("AmplitudeApiKey"), undefined, {
    appVersion: getBuildVersion().version,
  });
};
