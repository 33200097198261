import { add, isAfter } from "date-fns";

import { AnalyticsTool } from "@smart/bridge-types-basic";
import { isNotNullOrUndefined } from "@smart/itops-utils-basic";

type StoredKey = {
  encodedParam?: string;
  slugId?: string;
  teamId?: string;
};

type StoredInfo = {
  createdAt: string;
  id: string;
  slug: string;
  analyticsTools?: AnalyticsTool[];
};

const STORAGE_BASE_KEY = "smart-created-submission";
const EXPIRY_HOURS = 0;
const getStorageKey = ({ encodedParam, slugId, teamId }: StoredKey) =>
  [STORAGE_BASE_KEY, encodedParam, slugId, teamId]
    .filter(isNotNullOrUndefined)
    .join(".");

export const loadStoredSubmission = ({
  encodedParam,
  slugId,
  teamId,
}: StoredKey) => {
  const storedInfo: StoredInfo = JSON.parse(
    localStorage.getItem(getStorageKey({ encodedParam, slugId, teamId })) ||
      "{}",
  );
  const hasValidSubmission =
    !!storedInfo.id &&
    !!storedInfo.slug &&
    !!storedInfo.createdAt &&
    isAfter(
      add(new Date(storedInfo.createdAt), { hours: EXPIRY_HOURS }),
      new Date(),
    );

  return { storedInfo, hasValidSubmission };
};

export const saveStoredSubmission = (
  { encodedParam, slugId, teamId }: StoredKey,
  {
    slug,
    id,
    analyticsTools,
    createdAt,
  }: {
    slug: string;
    id: string;
    analyticsTools?: AnalyticsTool[];
    createdAt?: Date;
  },
) => {
  localStorage.setItem(
    getStorageKey({ encodedParam, slugId, teamId }),
    JSON.stringify({
      createdAt: (createdAt || new Date()).toISOString(),
      id,
      slug,
      analyticsTools,
    } as StoredInfo),
  );
};
