import styled from "@emotion/styled";

import { TimeOfDay } from "@smart/bridge-types-basic";
import { Button } from "@smart/itops-components-dom";

import { formatTimeOfDay, timeOfDayInMinutes } from "../../../utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem;
  padding: 0 2rem;
  width: 17rem;

  .heading {
    margin: 0 0 0.6rem 0.6rem;
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 500;
  }

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    margin: 1rem 0;
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: thin;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    max-width: 26.4rem;
    flex-direction: row;
    padding: 0;
    margin: 1rem 0;
  }
`;

const TimeslotButton = styled(Button)`
  margin: 0.4rem 0.8rem;
  padding: 1rem 2rem;
  border-radius: 5px;
  border-color: ${(props) => props.theme.palette.foreground.accent};
  color: ${(props) => props.theme.palette.foreground.base};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};

  &:hover {
    background: ${(props) =>
      props.disabled
        ? "transparent"
        : props.theme.palette.background.highlight};
  }
  &:focus {
    background: transparent;
  }

  &[aria-selected="true"] {
    background: ${(props) => props.theme.palette.secondary.accent};
  }

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin: 0.2rem;
  }
`;

type TimePickerProps = {
  heading?: string;
  selectedTime?: TimeOfDay | null;
  availableTimes?: TimeOfDay[];
  onSelect: (slot: TimeOfDay) => void;
};

export const TimePicker = ({
  heading,
  selectedTime,
  availableTimes,
  onSelect,
}: TimePickerProps) => (
  <Wrapper data-testid="intake-time-picker">
    {heading && <div className="heading">{heading}</div>}
    <Container>
      {availableTimes?.length
        ? availableTimes.map((slot) => (
            <TimeslotButton
              type="button"
              key={timeOfDayInMinutes(slot)}
              onClick={() => onSelect(slot)}
              aria-selected={
                !!selectedTime &&
                timeOfDayInMinutes(slot) === timeOfDayInMinutes(selectedTime)
              }
            >
              {formatTimeOfDay(slot)}
            </TimeslotButton>
          ))
        : null}
    </Container>
  </Wrapper>
);
