import styled from "@emotion/styled";
import { ForwardedRef, forwardRef } from "react";

import {
  Visibility,
  renderOrderedItems,
  useOrderedSections,
} from "../../hooks";
import { FieldItem, GroupItem, SectionItem, printTitle } from "../../types";
import { Page, Section } from "../section-components";
import {
  SummaryField,
  SummaryGroup,
  SummarySection,
} from "../summary-components";

const PrintingPageWrapper = styled.div`
  display: none;
`;

const PrintingPage = styled.div`
  @media print {
    margin: 1rem;
    display: block;
  }
`;

const PageTitle = styled.h1`
  font-size: ${(props) => props.theme.fontSize.heading};
  font-weight: bold;
  margin: 2rem 1rem 0rem 3rem;
`;

export type IntakePrintContentProps = {
  formTitle?: string;
  sections: SectionItem[];
  groups: GroupItem[];
  fields: FieldItem[];
  visibility?: Visibility;
  responses: Record<string, any>;
};

export const IntakePrintContent = forwardRef(
  (
    {
      formTitle,
      sections,
      groups,
      fields,
      visibility,
      responses,
    }: IntakePrintContentProps,
    forwardedRef: ForwardedRef<HTMLDivElement>,
  ) => {
    const orderedSections = useOrderedSections({
      sections,
      groups,
      fields,
      visibility,
    });

    return (
      <PrintingPageWrapper data-testid="intake-submission-print-content">
        <PrintingPage ref={forwardedRef}>
          <PageTitle>{formTitle || printTitle}</PageTitle>
          <Page>
            <Section>
              {orderedSections.map(({ orderedItems, section }) => (
                <SummarySection key={section.uri} section={section}>
                  {renderOrderedItems({
                    orderedItems,
                    responses,
                    renderGroup: (props) => <SummaryGroup {...props} />,
                    renderField: (props) => <SummaryField {...props} />,
                  })}
                </SummarySection>
              ))}
            </Section>
          </Page>
        </PrintingPage>
      </PrintingPageWrapper>
    );
  },
);
