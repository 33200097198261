import { useEffect, useRef, useState } from "react";

import { FieldGroup } from "@smart/itops-ui-dom";
import { isNullOrUndefined, partition } from "@smart/itops-utils-basic";

import { FieldSet } from "./input";
import { InputItem, InputItemOther, optionsWithFallback } from "./item";
import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

export const CheckboxField = ({
  field,
  index,
  innerRef,
  value,
  error,
  loading,
  disabled,
  onChange,
  onBlur,
}: FieldProps<HTMLInputElement>) => {
  const firstItemRef = useRef<HTMLInputElement>(null);
  const otherRef = useRef<HTMLInputElement>(null);

  const fieldOptionsWithFallback = optionsWithFallback(field.options);
  const [selected, otherValues] = partition(
    Array.isArray(value) ? value : [value],
    (s) => !!fieldOptionsWithFallback.find((o) => o.value === s),
  );
  const otherValue = otherValues.join(", ") || undefined;
  const [other, setOther] = useState(() => !!otherValue);
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });

  useEffect(() => {
    if (isNullOrUndefined(value)) setOther(false);
  }, [value]);

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <FieldGroup
        id={id}
        error={error}
        hint={field.hint ?? undefined}
        label={<Label {...field} index={index} />}
      >
        <FieldSet
          role="radiogroup"
          aria-invalid={!!error}
          aria-errormessage={error && errorId}
        >
          <input
            ref={innerRef}
            id={id}
            value={selected.join(", ") ?? ""}
            className="fieldset-value"
            aria-invalid={!!error}
            aria-errormessage={error && errorId}
            readOnly
            onFocus={() => {
              if (other) {
                otherRef.current?.focus();
              } else {
                firstItemRef.current?.focus();
              }
            }}
          />
          {fieldOptionsWithFallback.map((option, idx) => (
            <InputItem
              key={option.value || idx}
              uri={field.uri}
              index={index}
              type="checkbox"
              error={error}
              innerRef={idx === 0 ? firstItemRef : undefined}
              {...option}
              checked={selected.includes(option.value)}
              onChange={(v, checked) => {
                const update = checked
                  ? selected.filter((s) => s !== v)
                  : [...selected, v];
                if (otherValue) update.push(otherValue);
                onChange(update);
                onBlur();
              }}
            />
          ))}
          {field.allowCustomResponse && (
            <InputItem
              uri={field.uri}
              index={index}
              type="checkbox"
              label="Other"
              value="other"
              checked={other}
              error={error}
              onChange={() => {
                if (!other) {
                  setOther(true);
                  otherRef.current?.focus();
                } else {
                  setOther(false);
                  onChange(selected);
                  onBlur();
                }
              }}
            >
              <InputItemOther
                ref={otherRef}
                name={fieldName({ field, index, suffix: "other-input" })}
                type="text"
                value={otherValue ?? ""}
                aria-hidden={!other}
                aria-label="Other Value"
                onChange={(e) => {
                  if (e.currentTarget.value) {
                    onChange([...selected, e.currentTarget.value]);
                  } else {
                    onChange(selected);
                  }
                }}
                onBlur={onBlur}
              />
            </InputItem>
          )}
        </FieldSet>
      </FieldGroup>
    </FieldWrapper>
  );
};
