import {
  envKeys,
  googleMapApiKeys,
  googleCaptchaApiKeys,
  smokeballApiKeys,
} from "@smart/bridge-env-types-basic";
import { buildApiGatewayDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { keysOf } from "@smart/itops-utils-basic";

import { system } from "./base";
import { cdn } from "./cloudfront";

const lambdaDefs = {
  createSubmission: {
    pkg: "@smart/intake-create-submission-lambda",
    deployable: "createSubmission",
    method: "POST",
    authenticated: false,
    env: [],
  },
  load: {
    pkg: "@smart/intake-load-lambda",
    deployable: "load",
    method: "GET",
    authenticated: false,
    env: [...smokeballApiKeys],
  },
  submit: {
    pkg: "@smart/intake-submit-lambda",
    deployable: "submit",
    method: "POST",
    authenticated: false,
    env: [],
  },
  searchAddress: {
    pkg: "@smart/intake-search-address-lambda",
    deployable: "searchAddress",
    method: "GET",
    authenticated: false,
    env: [...googleMapApiKeys],
  },
  getAddress: {
    pkg: "@smart/intake-get-address-lambda",
    deployable: "getAddress",
    method: "POST",
    authenticated: false,
    env: [...googleMapApiKeys],
  },
  uploadFile: {
    pkg: "@smart/intake-upload-file-lambda",
    deployable: "uploadFile",
    method: "POST",
    authenticated: false,
    env: [],
  },
  recaptcha: {
    pkg: "@smart/intake-recaptcha-lambda",
    deployable: "recaptcha",
    method: "POST",
    authenticated: false,
    env: [...googleCaptchaApiKeys],
  },
} as const;

export const apiGateway = buildApiGatewayDef({
  envKeys,
  cdn,
  namespace: buildNamespace({ system, entity: "intake" }),
  prefix: "api",
  authorized: false,
  authorizer: undefined,
  lambdaKeys: keysOf(lambdaDefs),
  lambdas: lambdaDefs,
  port: 9101,
});
