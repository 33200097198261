import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";

import { loadEnv } from "@smart/bridge-env-dom";
import { PaymentResponse } from "@smart/bridge-types-basic";
import { Loading } from "@smart/itops-components-dom";
import { useAsync } from "@smart/itops-hooks-dom";
import { Icon } from "@smart/itops-icons-dom";
import { backoff, Env, isTestEnv, Region } from "@smart/itops-utils-basic";

import { ErrorDisplay } from "./error";
import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { LoadResponses } from "../../types";

const paymentPortalOrigins = {
  aus: {
    local: isTestEnv() ? "https://test.smartsb.dev" : "https://dev.smartsb.dev",
    dev: "https://devpayments-billing.smokeball.com.au",
    staging: "https://rcpayments-billing.smokeball.com.au",
    live: "https://payments-billing.smokeball.com.au",
  },
  nova: {
    local: isTestEnv() ? "https://test.smartsb.dev" : "https://dev.smartsb.dev",
    dev: "https://devpayments-billing.smokeball.com",
    staging: "https://rcpayments-billing.smokeball.com",
    live: "https://payments-billing.smokeball.com",
  },
  lon: {
    local: isTestEnv() ? "https://test.smartsb.dev" : "https://dev.smartsb.dev",
    dev: "https://devpayments-billing.smokeball.co.uk",
    staging: "https://rcpayments-billing.smokeball.co.uk",
    live: "https://payments-billing.smokeball.co.uk",
  },
} as const;

type ChargeDataMessage = {
  messageType: "CHARGE_DATA_READY";
  paymentRequestId: string;
  payload: {
    chargeRequest: PaymentResponse["chargeRequest"];
  };
};

const PaymentPortalWrapper = styled.div<{
  hasError?: boolean;
  hasInfo?: boolean;
}>`
  background: ${(props) =>
    props.hasInfo ? props.theme.scheme.grey.r20 : "none"};
  border: solid 1px
    ${(props) =>
      props.hasError
        ? props.theme.scheme.red.r100
        : props.theme.palette.disabled.base};
  border-radius: ${(props) => props.theme.baseUnit * 0.8}rem;
  box-shadow: ${(props) =>
    props.hasError ? `0 0 0 3px ${props.theme.scheme.red.r10}` : "none"};
  overflow: hidden;
  margin: 0.4rem 0;

  .payment-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    font-size: ${(props) => props.theme.fontSize.base};
    margin: 1rem;

    .check-icon {
      color: ${(props) => props.theme.scheme.green.r100};
    }
  }
`;

const PaymentPortal = styled.iframe`
  width: 100%;
  min-height: 95rem;
  border: none;
`;

export const PaymentField = ({
  field,
  index,
  value,
  error,
  loading,
  disabled,
  hideLabel,
  isPreview,
  onChange,
  onBlur,
  loadResponses,
}: FieldProps<HTMLDivElement, PaymentResponse> & {
  loadResponses: LoadResponses;
}) => {
  const frame = useRef<HTMLIFrameElement>(null);
  const [paymentResponse, setPaymentResponse] = useState<
    PaymentResponse | undefined
  >(value);

  const isSetupIncomplete =
    !field.payment?.amountInCents || !field.payment.bankAccount;

  useAsync(async () => {
    if (paymentResponse?.paymentPortalUrl || isSetupIncomplete || isPreview)
      return;

    const getPaymentPortalUrl = (
      responses: Awaited<ReturnType<LoadResponses>>,
    ) => responses?.[field.uri]?.value?.paymentPortalUrl;

    const responses = await backoff({
      run: loadResponses,
      test: (found) => !!getPaymentPortalUrl(found),
      attempts: 5,
      base: 1000,
      shouldCatch: true,
    })();

    if (getPaymentPortalUrl(responses)) {
      setPaymentResponse(responses?.[field.uri].value);
    }
  }, [paymentResponse?.paymentPortalUrl]);

  useEffect(() => {
    const region = loadEnv("Region") as Region;
    const env = loadEnv("Env") as Env;
    const listener = (event: MessageEvent<ChargeDataMessage>) => {
      const { data } = event;
      const isValid =
        data.messageType === "CHARGE_DATA_READY" &&
        data.paymentRequestId === paymentResponse?.linkId;
      if (
        event.origin === paymentPortalOrigins[region][env] &&
        paymentResponse &&
        isValid
      ) {
        onChange({
          ...paymentResponse,
          chargeRequest: data.payload.chargeRequest,
        });
        onBlur();
      }
    };
    window.addEventListener("message", listener);

    return () => window.removeEventListener("message", listener);
  }, [paymentResponse]);

  if (isSetupIncomplete) return null;

  const isLoading =
    !isPreview && (loading || !paymentResponse?.paymentPortalUrl);

  return (
    <FieldWrapper aria-disabled={disabled}>
      <Label {...field} index={index} hideLabel={hideLabel} />
      <PaymentPortalWrapper hasError={!!error} hasInfo={isPreview}>
        {!isPreview &&
          !value?.chargeRequest &&
          paymentResponse?.paymentPortalUrl && (
            <PaymentPortal
              ref={frame}
              title="Payment Portal"
              data-testid="payment-portal"
              src={paymentResponse.paymentPortalUrl}
            />
          )}
        <div className="payment-text">
          {value?.chargeRequest ? (
            <Icon
              className="check-icon"
              library="lucide"
              name="CheckCircle2"
              dataTestId="check-icon"
            />
          ) : (
            <Icon className="info-icon" library="lucide" name="Info" />
          )}
          {isPreview
            ? "Payment field is not visible in preview mode."
            : "Payment will be processed upon submission of this form."}
        </div>
        {isLoading && <Loading />}
      </PaymentPortalWrapper>
      <ErrorDisplay uri={field.uri} index={index} error={error} />
    </FieldWrapper>
  );
};
