import { UserNS, UserSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const userGuard = wrapSchema(UserSchema);

export const UserDef = buildTableDef(
  {
    namespace: UserNS,
    guard: userGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
  } as const,
  {
    byTeam: {
      indexName: "byTeam",
      keyFields: ["teamUri", "latestLoginAt"],
      keySchema: { teamUri: "string", latestLoginAt: "string" },
    },
  } as const,
);
