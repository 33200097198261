import { ResponseNS, ResponseSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const responseGuard = wrapSchema(ResponseSchema);

export const ResponseDef = buildTableDef(
  {
    namespace: ResponseNS,
    guard: responseGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
    ttlField: "deletedAtSec",
  } as const,
  {
    bySubmission: {
      indexName: "bySubmission",
      keyFields: ["submissionUri", "updatedAt"],
      keySchema: { submissionUri: "string", updatedAt: "string" },
    },
  } as const,
);
