import {
  LDContext,
  LDProvider,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import {
  ComponentType,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { loadEnv } from "@smart/bridge-env-dom";
import {
  extractPrefixedTeamId,
  regionToCountry,
} from "@smart/bridge-types-basic";
import { Region, v4 } from "@smart/itops-utils-basic";

import {
  defaultLocalSmokeballFeatureFlags,
  useSmokeballFeatureFlags,
} from "./hooks";
import {
  SmokeballFeatureFlags,
  SmokeballLDIdentity,
  SmokeballLaunchDarkly,
} from "./types";
import { skipFeatureFlags } from "./utils";

export const SBFeatureFlagContext = createContext<SmokeballLaunchDarkly>({
  featureFlags: {
    matterTypes: "",
    fusionFamilyPropertyIntegrationV2: {
      QuestionnaireMatterTypeIds: [],
      FamilyPropertyMatterTypeIds: [],
      IntakeMatterTypeIds: [],
    },
  },
  setIdentity: () => {},
});

type SmokeballLDClientProps = {
  setSmokeballFlags: React.Dispatch<
    React.SetStateAction<SmokeballFeatureFlags>
  >;
  identity: SmokeballLDIdentity | undefined;
};

export const SmokeballLDClient = ({
  setSmokeballFlags,
  identity,
}: SmokeballLDClientProps) => {
  const client = useLDClient();
  const sbFeatureFlagsValue = useSmokeballFeatureFlags();
  const region = loadEnv("Region") as Region;

  const identify = async () => {
    if (client && identity) {
      const context: LDContext = {
        kind: "multi",
        user: {
          key: identity.team?.uri
            ? extractPrefixedTeamId(identity.team?.uri, "sb").id
            : `anonymous-${v4()}`,
          name: identity.team?.name,
          country: regionToCountry[region],
          product: identity.team?.provider,
        },
      };

      await client.identify(context);
    }
  };

  useEffect(() => {
    if (!skipFeatureFlags()) identify().catch(console.error);
  }, [client, identity]);

  useEffect(() => {
    const sbFeatureFlags = {
      matterTypes: "",
      fusionFamilyPropertyIntegrationV2: {
        QuestionnaireMatterTypeIds: [],
        FamilyPropertyMatterTypeIds: [],
        IntakeMatterTypeIds: [],
      },
    };

    try {
      sbFeatureFlags.matterTypes = sbFeatureFlagsValue.matterTypes || "";
      if (sbFeatureFlagsValue.fusionFamilyPropertyIntegrationV2) {
        const fpFlag = JSON.parse(
          sbFeatureFlagsValue.fusionFamilyPropertyIntegrationV2,
        );
        sbFeatureFlags.fusionFamilyPropertyIntegrationV2.QuestionnaireMatterTypeIds =
          fpFlag?.QuestionnaireMatterTypeIds || [];
        sbFeatureFlags.fusionFamilyPropertyIntegrationV2.FamilyPropertyMatterTypeIds =
          fpFlag?.FamilyPropertyMatterTypeIds || [];
        sbFeatureFlags.fusionFamilyPropertyIntegrationV2.IntakeMatterTypeIds =
          fpFlag?.IntakeMatterTypeIds || [];
      }
    } catch (error) {
      console.error("Unexpected feature flags for FamilyProIntegration");
    }

    setSmokeballFlags(sbFeatureFlags);
  }, [sbFeatureFlagsValue]);

  return null;
};

export const withSmokeballFeatureFlags = (App: ComponentType) => () => {
  const [identity, setIdentity] = useState<SmokeballLDIdentity>();
  const [smokeballFlags, setSmokeballFlags] = useState<SmokeballFeatureFlags>({
    matterTypes: "",
    fusionFamilyPropertyIntegrationV2: {
      QuestionnaireMatterTypeIds: [],
      FamilyPropertyMatterTypeIds: [],
      IntakeMatterTypeIds: [],
    },
  });

  const contextValue = useMemo(
    () => ({
      featureFlags: smokeballFlags,
      setIdentity,
    }),
    [smokeballFlags, setIdentity],
  );

  return (
    <SBFeatureFlagContext.Provider value={contextValue}>
      <LDProvider clientSideID={loadEnv("SmokeballLaunchDarklyClientSideId")}>
        <SmokeballLDClient
          identity={identity}
          setSmokeballFlags={setSmokeballFlags}
        />
      </LDProvider>
      <App />
    </SBFeatureFlagContext.Provider>
  );
};

export const withLocalSmokeballFeatureFlags = (App: ComponentType) => () => {
  const contextValue = useMemo(
    () => ({
      featureFlags: defaultLocalSmokeballFeatureFlags,
      setIdentity: () => {},
    }),
    [App],
  );

  return (
    <SBFeatureFlagContext.Provider value={contextValue}>
      <App />
    </SBFeatureFlagContext.Provider>
  );
};

export const useSmokeballFeatureFlagsContext = () =>
  useContext(SBFeatureFlagContext);
