import { z } from "zod";

import { system } from "@smart/bridge-types-basic";
import { buildSNSSQSDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const RequeueNS = buildNamespace({
  system,
  entity: "Requeue",
} as const);

export const RequeueSchema = z.object({
  id: z.string(),
  transport: z.string(),
  requeue: z.boolean(),
});

const requeueGuard = wrapSchema(RequeueSchema);

export const RequeueDef = buildSNSSQSDef({
  namespace: RequeueNS,
  guard: requeueGuard,
  sqsOnly: false,
  groupKey: "transport",
  idKey: "id",
});
