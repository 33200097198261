/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import styled from "@emotion/styled";
import { RefObject } from "react";

import { Displayer } from "@smart/itops-editor-dom";

import { SectionErrors, sectionFallback, SectionItem } from "../../types";
import { Indicator } from "../field-components";
import { ErrorSummary } from "../shared-components/error-summary";

export const SectionGap = styled.div`
  margin: 0 0 3rem;
`;

export const SectionHeadingWrapper = styled.header`
  h1 {
    font-size: ${(props) => props.theme.fontSize.heading};
    font-weight: 600;
    margin: ${(props) => props.theme.baseUnit}rem 0
      ${(props) => props.theme.baseUnit * 0.6}rem;
  }

  p {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;

    small {
      font-size: ${(props) => props.theme.fontSize.small};
    }
  }
`;

export type SectionHeadingProps = {
  section: SectionItem;
  errorsRef?: RefObject<HTMLDivElement>;
  errors?: SectionErrors;
  hideErrors?: boolean;
};

export const SectionHeading = ({
  section: { title, description, hasRequired },
  errorsRef,
  errors,
  hideErrors,
}: SectionHeadingProps) => (
  <SectionHeadingWrapper>
    <Displayer paragraphTag="h1" value={title} fallback={sectionFallback} />
    <Displayer value={description} />
    {hasRequired && (
      <p>
        <small>
          <Indicator /> indicates required information
        </small>
      </p>
    )}
    {errors && errors.total > 1 && !hideErrors && (
      <ErrorSummary errors={errors.errors} errorsRef={errorsRef} />
    )}
  </SectionHeadingWrapper>
);
