import { TrackerNS, TrackerSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const trackerGuard = wrapSchema(TrackerSchema);

export const TrackerDef = buildTableDef(
  {
    namespace: TrackerNS,
    guard: trackerGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
  } as const,
  {
    bySubmission: {
      indexName: "bySubmission",
      keyFields: ["submissionUri", "sentAt"],
      keySchema: { submissionUri: "string", sentAt: "string" },
    },
  } as const,
);
