import { FieldGroup, TextInput } from "@smart/itops-ui-dom";

import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

export const NumberField = ({
  field,
  index,
  innerRef,
  value,
  error,
  loading,
  disabled,
  onChange,
  onBlur,
}: FieldProps<HTMLInputElement>) => {
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <FieldGroup
        id={id}
        error={error}
        hint={field.hint ?? undefined}
        label={<Label {...field} index={index} />}
      >
        <TextInput
          ref={innerRef}
          id={id}
          name={id}
          error={!!error}
          maxLength={16}
          aria-invalid={!!error}
          aria-errormessage={errorId ?? undefined}
          value={value?.toString().replace(/[^\d]/g, "") ?? ""}
          inputMode="numeric"
          pattern="\d*"
          onChange={(e) => {
            const asString = e.target.value.replace(/[^\d]/g, "");
            const asNumber = parseInt(asString, 10);

            onChange(Number.isNaN(asNumber) ? null : asNumber);
          }}
          onBlur={onBlur}
          size="base"
        />
      </FieldGroup>
    </FieldWrapper>
  );
};
