import { FieldNS, FieldSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const fieldGuard = wrapSchema(FieldSchema);

export const FieldDef = buildTableDef(
  {
    namespace: FieldNS,
    guard: fieldGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
    ttlField: "deletedAtSec",
  } as const,
  {
    byForm: {
      indexName: "byForm",
      keyFields: ["formUri", "order"],
      keySchema: { formUri: "string", order: "string" },
    },
    bySection: {
      indexName: "bySection",
      keyFields: ["sectionUri", "order"],
      keySchema: { sectionUri: "string", order: "string" },
    },
    byGroup: {
      indexName: "byGroup",
      keyFields: ["groupUri", "order"],
      keySchema: { groupUri: "string", order: "string" },
    },
  } as const,
);
