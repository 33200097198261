import { z } from "zod";

import { system } from "@smart/bridge-types-basic";
import { buildSNSSQSDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const BackoffNS = buildNamespace({ system, entity: "Backoff" } as const);

export const BackoffSchema = z.object({
  topicArn: z.string(),
  groupId: z.string().optional(),
  deduplicationId: z.string().optional(),
  content: z.any(),
});

const backoffGuard = wrapSchema(BackoffSchema);

export const BackoffDef = buildSNSSQSDef({
  namespace: BackoffNS,
  guard: backoffGuard,
  maxAttempts: 1,
  sqsOnly: true,
  groupKey: undefined,
  idKey: undefined,
});
