export const fieldName = ({
  field: { uri },
  index,
  suffix,
}: {
  field: { uri: string };
  index?: number;
  suffix?: string;
}) =>
  `${uri}${index !== undefined ? `[${index}]` : ""}${
    suffix ? `-${suffix}` : ""
  }`;
