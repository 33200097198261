import { ReactNode } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { loadEnv } from "@smart/bridge-env-dom";

export type ReCaptchaProviderProps = { children: ReactNode };

export const ReCaptchaProvider = ({ children }: ReCaptchaProviderProps) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={loadEnv("GoogleCaptchaKey")}
    container={{
      element: "recaptcha",
      parameters: { badge: "bottomright" },
    }}
    useEnterprise
  >
    {children}
  </GoogleReCaptchaProvider>
);
