import { useEffect, useRef } from "react";

import { SubmissionSyncStatus } from "@smart/bridge-types-basic";

export const useLoadMatterFields = (
  {
    submissionSyncStatus,
    fn,
  }: {
    submissionSyncStatus: SubmissionSyncStatus | undefined | null;
    fn: Function;
  },
  deps: any[],
) => {
  const isLoadingMatterFields = useRef(false);

  useEffect(() => {
    if (submissionSyncStatus === "loading" && !isLoadingMatterFields.current) {
      isLoadingMatterFields.current = true;
    }

    if (isLoadingMatterFields.current) {
      fn();
    }

    if (isLoadingMatterFields.current && submissionSyncStatus !== "loading") {
      isLoadingMatterFields.current = false;
    }
  }, deps);
};
