import styled from "@emotion/styled";
import { useRef } from "react";

import { FormAIFillSettings, StaffDetails } from "@smart/bridge-types-basic";
import { Heading } from "@smart/itops-ui-dom";
import { mapEntries } from "@smart/itops-utils-basic";

import { AutofillUnavailableModal } from "./autofill-components";
import {
  IntakeFormAutoFill,
  IntakeFormSection,
  IntakeFormSummary,
} from "./form-components";
import { TeamLabel } from "./shared-components/team-label";
import { IntakeSidebar } from "./sidebar";
import { IntakeStatus } from "./status";
import { IntakeFormHookProps, useIntakeForm } from "../hooks";
import {
  IntakeFormSectionRef,
  LookupOptions,
  SectionItem,
  TeamItem,
} from "../types";

const IntakeHeading = styled(Heading)`
  .heading-wrapper {
    box-shadow: none;
    border-bottom: 1px solid
      ${(props) => props.theme.palette.background.highlight};
    height: ${(props) => props.theme.baseUnit * 7}rem;
    z-index: 1;
  }
`;

const Backdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 80;

  margin: 0;
  padding: 0;
  border: 0;
  background: ${(props) => props.theme.palette.background.highlight};
  opacity: 0.4;
`;

export type IntakeFormProps = IntakeFormHookProps & {
  isMatterClosed?: boolean;
  form: {
    team: TeamItem;
    hideTeam?: boolean;
    response?: string;
    category?: string;
    aiFillSettings?: FormAIFillSettings;
  };
  lookup: LookupOptions;
  previewOptions?: {
    previewErrorToggle: [
      boolean,
      React.Dispatch<React.SetStateAction<boolean>>,
    ];
    previewResponses: [
      Record<string, any>,
      React.Dispatch<React.SetStateAction<Record<string, any>>>,
    ];
    previewLastSection: [
      SectionItem | "summary" | undefined,
      React.Dispatch<React.SetStateAction<SectionItem | "summary" | undefined>>,
    ];
  };
  isInternal?: boolean;
  newUI?: boolean;
  staffDetails: StaffDetails[];
  showConnectionBanner?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
  intakeFormKey?: string;
  isPreview?: boolean;
  aiWordSupport?: boolean;
  loading?: boolean;
  isAutoFillFileUploadDisabled?: boolean;
};

export const IntakeForm = ({
  isMatterClosed,
  intakeFormKey,
  form,
  lookup,
  previewOptions,
  isInternal,
  newUI,
  staffDetails,
  showConnectionBanner,
  aiWordSupport,
  loading,
  isAutoFillFileUploadDisabled,
  ...formOptions
}: IntakeFormProps) => {
  const {
    summary,
    statusProps,
    sidebarProps,
    sectionProps,
    summaryProps,
    printProps,
    uploadFileProps,
    autoFillProps,
    autoFillUnavailableProps,
  } = useIntakeForm({
    ...formOptions,
    aiFillSettings: form.aiFillSettings,
    previewLastSection: previewOptions?.previewLastSection,
    submission: {
      ...formOptions.submission,
      responses:
        (previewOptions?.previewResponses[0]
          ? mapEntries<Record<string, any>, Record<string, { value: any }>>(
              previewOptions.previewResponses[0],
              (value) => ({
                value,
              }),
            )
          : undefined) || formOptions.submission.responses,
    },
    isPreview: formOptions.isPreview,
  });

  const sectionRef = useRef<IntakeFormSectionRef>(null);

  if (
    isMatterClosed ||
    (statusProps.status !== "active" && !isInternal) ||
    statusProps.deleted
  ) {
    return (
      <IntakeStatus
        {...form}
        {...statusProps}
        printOptions={printProps}
        isMatterClosed={isMatterClosed}
      />
    );
  }

  const aiFillFromMatterInfo = !!formOptions.submission.aiFillFromMatterInfo;
  const pageDisabled =
    formOptions.autoFillStatus === "filling" ||
    (aiFillFromMatterInfo && loading);

  const renderContent = () => {
    if (summary) {
      return (
        <IntakeFormSummary
          {...form}
          {...summaryProps}
          {...sectionProps}
          previewOptions={
            previewOptions
              ? {
                  previewResponses: previewOptions.previewResponses[0],
                  clearLastPreviewSection: () =>
                    previewOptions.previewLastSection[1](undefined),
                  hidePreviewErrors: previewOptions.previewErrorToggle[0],
                }
              : undefined
          }
        />
      );
    }

    if (autoFillProps.isAutoFillSectionSelected) {
      return (
        <IntakeFormAutoFill
          {...form}
          {...autoFillProps}
          aiWordSupport={aiWordSupport}
          uploadFileProps={uploadFileProps}
          fileLookup={lookup.fileLookup}
          isFileUploadDisabled={isAutoFillFileUploadDisabled}
        />
      );
    }

    return (
      <IntakeFormSection
        {...form}
        {...lookup}
        {...sectionProps}
        uploadFileProps={uploadFileProps}
        ref={sectionRef}
        submissionSyncStatus={formOptions.submission.syncStatus}
        previewOptions={previewOptions}
        staffDetails={staffDetails}
        showConnectionBanner={showConnectionBanner}
        loadResponses={formOptions.loadResponses}
        aiFillFromMatterInfo={aiFillFromMatterInfo}
        loading={loading}
        isPreview={formOptions.isPreview}
      />
    );
  };

  return (
    <IntakeSidebar {...sidebarProps} sectionRef={sectionRef} newUI={newUI}>
      {!form.hideTeam && (
        <IntakeHeading
          left={
            <TeamLabel
              teamName={form.team.name}
              teamPicture={form.team.picture}
            />
          }
        />
      )}
      {pageDisabled && <Backdrop />}
      {renderContent()}
      <AutofillUnavailableModal {...autoFillUnavailableProps} />
    </IntakeSidebar>
  );
};
