import { sortField } from "@smart/itops-utils-basic";

import { Visibility } from "./visibility";
import { FieldItem, GroupItem, OrderedItem, SectionItem } from "../types";

export type OrderedItemsOptions = {
  selected?: SectionItem;
  groups: GroupItem[];
  fields: FieldItem[];
  visibility?: Visibility;
};

export const useOrderedItems = ({
  selected,
  groups,
  fields,
  visibility,
}: OrderedItemsOptions): OrderedItem[] => {
  if (!selected) return [];

  const orderedGroups: OrderedItem[] = groups
    .filter(
      (g) =>
        g.sectionUri === selected.uri &&
        (!visibility || !!visibility.visibleGroups[g.uri]),
    )
    .map((group) => {
      const groupOrderedFields = sortField(
        fields.filter(
          (f) =>
            f.sectionUri === selected.uri &&
            f.groupUri === group.uri &&
            (!visibility || !!visibility.visibleFields[f.uri]),
        ),
        { key: "order", dir: "asc" },
      );
      return {
        group,
        type: "group" as const,
        order: group.order,
        fields: groupOrderedFields,
      };
    })
    .filter((g) => !!g.fields.length);

  const orderedFields: OrderedItem[] = fields
    .filter(
      (f) =>
        f.sectionUri === selected.uri &&
        !f.groupUri &&
        (!visibility || !!visibility.visibleFields[f.uri]),
    )
    .map((field) => ({
      field,
      type: "field",
      order: field.order,
    }));

  return sortField([...orderedFields, ...orderedGroups], {
    key: "order",
    dir: "asc",
  });
};
