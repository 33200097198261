import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";

import { AnalyticsTool } from "@smart/bridge-types-basic";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type AnalyticsEvent = {
  name: "form_submission";
  params: {
    form_title: string;
  };
};

type AnalyticsValue = {
  sendEvent: (event: AnalyticsEvent) => void;
};

const AnalyticsContext = createContext<AnalyticsValue>({
  sendEvent: () => {},
});

export const useAnalytics = () => useContext(AnalyticsContext);

export type AnalyticsProviderProps = {
  config: AnalyticsTool[] | undefined;
  children: ReactNode;
};

export const AnalyticsProvider = ({
  children,
  config,
}: AnalyticsProviderProps) => {
  // This can't be an arrow function as dataLayer expects arguments object
  // eslint-disable-next-line func-names
  const googleTag: Function = function () {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer?.push(arguments);
  };
  const [value] = useState<AnalyticsValue>({
    sendEvent: (event) => {
      googleTag("event", event.name, event.params);
    },
  });

  const gAMeasurementId = config?.find(
    ({ type }) => type === "googleAnalytics",
  )?.trackingId;

  useEffect(() => {
    if (gAMeasurementId) {
      window.dataLayer = window.dataLayer || [];
      googleTag("js", new Date());
      googleTag("config", gAMeasurementId, {
        cookie_flags: "samesite=none;secure",
      });
    }
  }, [gAMeasurementId]);

  return (
    <AnalyticsContext.Provider value={value}>
      {gAMeasurementId && (
        // Google tag script needs to be in the <head> tag to work
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gAMeasurementId}`}
            data-testid="google-analytics-script-tag"
          />
        </Helmet>
      )}
      {children}
    </AnalyticsContext.Provider>
  );
};
