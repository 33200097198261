import {
  format,
  getHours,
  parseISO,
  addMinutes,
  getMinutes,
  isSameDay,
  addDays,
} from "date-fns";
import {
  formatInTimeZone,
  fromZonedTime,
  getTimezoneOffset,
  toZonedTime,
} from "date-fns-tz";

import {
  AvailabilityItem,
  AvailabilityWithTimeZone,
  TimeOfDay,
} from "@smart/bridge-types-basic";
import { specialChars } from "@smart/itops-utils-basic";

import { WeekDay, maxTimeOfDay, minTimeOfDay } from "../types";

export const formatTime = ({
  hour,
  minute,
  format: timeFormat,
}: {
  hour: number;
  minute: number;
  format: "12hours" | "24hours";
}) => {
  const date = new Date();
  date.setHours(hour, minute, 0);

  return timeFormat === "12hours"
    ? format(date, "h:mm a")
    : format(date, "HH:mm:ss");
};

export const addMins = ({
  hour,
  minute,
  duration,
}: {
  hour: number;
  minute: number;
  duration: number;
}) => {
  const date = new Date();
  date.setHours(hour, minute);
  const newDate = addMinutes(date, duration);

  return {
    hour: getHours(newDate),
    minute: getMinutes(newDate),
  };
};

export const displayAppointmentValues = ({
  startTime,
  endTime,
  timezone,
}: {
  startTime: string | undefined;
  endTime: string | undefined;
  timezone: string | undefined;
}) => {
  const timeToDisplay = (timeStr: string) => {
    const dateTime = parseISO(timeStr);
    return timezone
      ? formatInTimeZone(dateTime, timezone, "hh:mm a")
      : format(dateTime, "hh:mm a");
  };

  const dayToDisplay = (timeStr: string) => {
    const dateTime = parseISO(timeStr);
    return timezone
      ? formatInTimeZone(dateTime, timezone, "EEEE do MMM yyyy")
      : format(dateTime, "EEEE do MMM yyyy");
  };

  const hasDate = !!startTime && !!endTime;
  return {
    day: hasDate ? dayToDisplay(startTime) : specialChars.enDash,
    time: hasDate
      ? `${timeToDisplay(startTime)} - ${timeToDisplay(endTime)}`
      : specialChars.enDash,
  };
};

export const formatTimeOfDay = (time: TimeOfDay): string => {
  const hour = time.hour % 12 || 12;
  const minute = time.minute.toString().padStart(2, "0");
  const period = time.hour < 12 ? "AM" : "PM";

  return `${hour}:${minute} ${period}`;
};

export const timeOfDayInMinutes = (time: TimeOfDay): number =>
  time.hour * 60 + time.minute;

export const toTimeOfDay = (minutes: number): TimeOfDay => ({
  hour: Math.floor(minutes / 60),
  minute: minutes % 60,
});

export const buildTimeOfDayRange = (options?: {
  start?: TimeOfDay;
  end?: TimeOfDay;
  duration?: number;
}): TimeOfDay[] => {
  const startInMinutes = timeOfDayInMinutes(options?.start || minTimeOfDay);
  const endInMinutes = timeOfDayInMinutes(options?.end || maxTimeOfDay);
  if (startInMinutes > endInMinutes) return [];

  const range = [];

  const duration = options?.duration || 30;
  let current = startInMinutes;
  while (current <= endInMinutes) {
    range.push(toTimeOfDay(current));
    current += duration;
  }

  return range;
};

type CalculateAvailableTimeSlotsProps = {
  date: Date | undefined | null;
  duration: number;
  availability: AvailabilityItem[];
  creationTimeZone?: string;
  blocked: {
    fromTime: string;
    toTime: string;
    timezone?: string | null;
  }[];
  clientTimeZone: string;
  minimumNoticeInMinutes?: number;
  bufferTimeInMinutes?: number;
};

export const calculateExtendedAvailableTimeSlots = ({
  date: selectedDate,
  calculatedDate,
  duration,
  availability,
  creationTimeZone,
  blocked,
  clientTimeZone,
  minimumNoticeInMinutes,
  bufferTimeInMinutes,
}: CalculateAvailableTimeSlotsProps & {
  calculatedDate: Date;
}): TimeOfDay[] => {
  // NOTE: if a timezone is missing, it will be considered as UTC timezone then.
  if (!selectedDate) return [];

  const utcDate = fromZonedTime(calculatedDate, clientTimeZone);

  const currentAvailability = availability.find(
    (a) => a.day === calculatedDate.getDay() && a.enabled,
  );
  if (!currentAvailability) return [];

  const convertAvailabilityRange = ({
    onDate,
    fromTime,
    toTime,
    timezone,
  }: {
    onDate: Date;
    fromTime: TimeOfDay;
    toTime: TimeOfDay;
    timezone?: string;
  }): { fromDateTime: Date; toDateTime: Date } => {
    const from = new Date(onDate);
    from.setHours(fromTime.hour, fromTime.minute, 0);

    const to = new Date(onDate);
    to.setHours(toTime.hour, toTime.minute, 0);

    return {
      fromDateTime: timezone ? fromZonedTime(from, timezone) : from,
      toDateTime: timezone ? fromZonedTime(to, timezone) : to,
    };
  };

  const isBeingBlocked = (
    dateToCheck: Date,
    blockedDateTimes: { fromDateTime: Date; toDateTime: Date }[],
  ): boolean =>
    blockedDateTimes.length > 0 &&
    blockedDateTimes.some(
      (b) =>
        b.fromDateTime.getTime() <= dateToCheck.getTime() &&
        dateToCheck.getTime() < b.toDateTime.getTime(),
    );

  const utcBlocked = blocked.map((b) => ({
    fromDateTime: b.timezone
      ? fromZonedTime(b.fromTime, b.timezone)
      : new Date(b.fromTime),
    toDateTime: b.timezone
      ? fromZonedTime(b.toTime, b.timezone)
      : new Date(b.toTime),
  }));
  const utcBlockedOnDateWithBuffer = utcBlocked
    .filter(
      (b) =>
        isSameDay(utcDate, b.fromDateTime) || isSameDay(utcDate, b.toDateTime),
    )
    .map((b) => ({
      fromDateTime: addMinutes(b.fromDateTime, -(bufferTimeInMinutes || 0)),
      toDateTime: addMinutes(b.toDateTime, bufferTimeInMinutes || 0),
    }));
  const utcCurrentAvailableRange = convertAvailabilityRange({
    onDate: utcDate,
    ...currentAvailability,
    timezone: creationTimeZone,
  });
  const today = new Date();
  const earliestAvailable = addMinutes(today, minimumNoticeInMinutes || 0);

  const utcAvailableTimeSlots = [];
  let current = utcCurrentAvailableRange.fromDateTime;

  while (current.getTime() <= utcCurrentAvailableRange.toDateTime.getTime()) {
    if (
      current.getTime() >= earliestAvailable.getTime() &&
      !isBeingBlocked(current, utcBlockedOnDateWithBuffer)
    ) {
      utcAvailableTimeSlots.push(new Date(current));
    }

    current = addMinutes(current, duration);
  }

  return utcAvailableTimeSlots
    .map((slot) => {
      const inTimeZoneDateTime = toZonedTime(slot, clientTimeZone);

      return {
        date: inTimeZoneDateTime.getDate(),
        hour: inTimeZoneDateTime.getHours(),
        minute: inTimeZoneDateTime.getMinutes(),
      };
    })
    .filter((available) => available.date === selectedDate.getDate())
    .map((available) => ({
      hour: available.hour,
      minute: available.minute,
    }));
};

export const calculateAvailableTimeSlots = (
  props: CalculateAvailableTimeSlotsProps,
): TimeOfDay[] => {
  const { date: currentDate } = props;
  if (!currentDate) return [];

  const previousDate = addDays(currentDate, -1);
  const nextDate = addDays(currentDate, 1);

  return [
    ...calculateExtendedAvailableTimeSlots({
      ...props,
      calculatedDate: previousDate,
    }),
    ...calculateExtendedAvailableTimeSlots({
      ...props,
      calculatedDate: currentDate,
    }),
    ...calculateExtendedAvailableTimeSlots({
      ...props,
      calculatedDate: nextDate,
    }),
  ];
};

export const getLastDisplayDate = ({
  startDate,
  excludingWeekDays,
  numberOfDisplayDate,
}: {
  startDate: Date;
  excludingWeekDays?: WeekDay[];
  numberOfDisplayDate: number;
}): Date => {
  let endDate = startDate;
  let availableDates = 0;
  while (availableDates < numberOfDisplayDate) {
    if (!excludingWeekDays?.includes(endDate.getDay())) availableDates += 1;
    if (availableDates < numberOfDisplayDate) endDate = addDays(endDate, 1);
  }

  return endDate;
};

export const convertAvailability = (
  { availability, timezone: fromTimeZone }: AvailabilityWithTimeZone,
  toTimeZone: string,
  referencedDate?: Date,
): AvailabilityWithTimeZone => {
  if (!fromTimeZone || !toTimeZone || fromTimeZone === toTimeZone)
    return {
      availability,
      timezone: toTimeZone,
    };

  const lastTimeOfDayInMinute = timeOfDayInMinutes({ hour: 24, minute: 0 });
  const firstTimeOfDayInMinute = timeOfDayInMinutes({ hour: 0, minute: 0 });

  const offsetInMinute =
    (getTimezoneOffset(toTimeZone, referencedDate) -
      getTimezoneOffset(fromTimeZone, referencedDate)) /
    (60 * 1000);

  const converted: AvailabilityItem[] = [];
  const enabledAvailability = availability.filter((a) => a.enabled);

  for (const slot of enabledAvailability) {
    const offsetFrom = timeOfDayInMinutes(slot.fromTime) + offsetInMinute;
    const offsetTo = timeOfDayInMinutes(slot.toTime) + offsetInMinute;

    if (offsetFrom < 0) {
      converted.push({
        day: slot.day >= 1 ? slot.day - 1 : 6,
        fromTime: toTimeOfDay(lastTimeOfDayInMinute + offsetFrom),
        toTime:
          offsetTo <= 0
            ? toTimeOfDay(lastTimeOfDayInMinute + offsetTo)
            : toTimeOfDay(lastTimeOfDayInMinute),
        enabled: true,
      });

      if (offsetTo > 0) {
        converted.push({
          day: slot.day,
          fromTime: toTimeOfDay(firstTimeOfDayInMinute),
          toTime: toTimeOfDay(offsetTo),
          enabled: true,
        });
      }
    } else if (offsetTo > lastTimeOfDayInMinute) {
      converted.push({
        day: slot.day,
        fromTime: toTimeOfDay(offsetFrom),
        toTime: toTimeOfDay(lastTimeOfDayInMinute),
        enabled: true,
      });
      converted.push({
        day: (slot.day + 1) % 7,
        fromTime: toTimeOfDay(firstTimeOfDayInMinute),
        toTime: toTimeOfDay(offsetTo - lastTimeOfDayInMinute),
        enabled: true,
      });
    } else {
      converted.push({
        day: slot.day,
        fromTime: toTimeOfDay(offsetFrom),
        toTime: toTimeOfDay(offsetTo),
        enabled: true,
      });
    }
  }

  return {
    availability: converted,
    timezone: toTimeZone,
  };
};

export const getDisplayDates = ({
  startDate,
  numberOfDisplayDate,
  availabilityWithTimeZone,
  toTimezone,
}: {
  startDate: Date;
  numberOfDisplayDate: number;
  availabilityWithTimeZone: AvailabilityWithTimeZone;
  toTimezone?: string | null;
}): {
  excludingDates: Date[];
  startDate: Date;
  endDate: Date;
} => {
  const excludingDates = [];
  let endDate = startDate;
  let availableDates = 0;

  const zonedAvailability = toTimezone
    ? convertAvailability(availabilityWithTimeZone, toTimezone, startDate)
    : availabilityWithTimeZone;

  const isEnabled = (date: Date) =>
    zonedAvailability.availability.find(
      (a) => a.day === date.getDay() && a.enabled,
    );

  while (availableDates < numberOfDisplayDate) {
    if (isEnabled(endDate)) {
      availableDates += 1;
    } else {
      excludingDates.push(new Date(endDate));
    }
    if (availableDates < numberOfDisplayDate) {
      endDate = addDays(endDate, 1);
    }
  }

  return {
    excludingDates,
    startDate,
    endDate,
  };
};
