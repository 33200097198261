import { TeamNS, TeamSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const teamGuard = wrapSchema(TeamSchema);

export const TeamDef = buildTableDef(
  {
    namespace: TeamNS,
    guard: teamGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
  } as const,
  {} as const,
);
