import { FloatingFocusManager } from "@floating-ui/react";
import { useEffect, useRef, useState } from "react";

import { Icon } from "@smart/itops-icons-dom";
import { isNullOrUndefined, partition } from "@smart/itops-utils-basic";

import { ErrorDisplay } from "./error";
import { InputWrapper } from "./input";
import { optionsWithFallback } from "./item";
import { Label } from "./label";
import { Item, ItemList, OtherInput, useFloatingList } from "./list-field";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

export const MultiList = ({
  field,
  index,
  innerRef,
  value,
  error,
  onChange,
  onBlur,
  className,
}: Omit<FieldProps<HTMLInputElement>, "disabled" | "field"> & {
  className?: string;
  field: {
    uri: string;
    options?: { label: string; value: string }[] | null;
    allowCustomResponse?: boolean | null;
  };
}) => {
  const otherRef = useRef<HTMLInputElement>(null);

  const [selected, otherValues] = partition(
    Array.isArray(value) ? value : [value],
    (s) => !!field.options?.find((o) => o.value === s),
  );
  const otherValue = otherValues.join(", ") || undefined;
  const [other, setOther] = useState(() => !!otherValue);

  const items = optionsWithFallback(field.options);
  const getSearchTerm = () =>
    [
      ...selected.map((s) => items.find((i) => i.value === s)?.label || s),
      other ? "Other" : undefined,
    ]
      .filter(Boolean)
      .join(", ");
  const [searchTerm, setSearchTerm] = useState(getSearchTerm);
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });

  const {
    onOpenChange,
    refs,
    listRef,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    activeIndex,
    open,
    context,
  } = useFloatingList();

  const onSelect = (item: { label: string; value: string }) => {
    const checked = selected.includes(item.value);
    const update = checked
      ? selected.filter((s) => s !== item.value)
      : [...selected, item.value];
    if (otherValue) update.push(otherValue);
    onChange(update);
    onBlur();
  };
  const toggleOther = () => {
    if (!other) {
      onOpenChange(false);
      setOther(true);
      setTimeout(() => {
        otherRef.current?.focus();
      }, 0);
    } else {
      setOther(false);
      onChange(selected);
      onBlur();
    }
  };

  useEffect(() => {
    if (isNullOrUndefined(value)) {
      setOther(false);
    } else if (!open) {
      setSearchTerm(getSearchTerm());
      setOther(!!otherValue);
    }
  }, [value]);

  useEffect(() => {
    if (!open && (selected.length || other)) {
      setSearchTerm(getSearchTerm());
    }
  }, [open]);

  const checkMatch = (text: string) =>
    searchTerm
      .split(",")
      .map((t) => t.trim())
      .filter(Boolean)
      .some((term) => text.toLowerCase().includes(term.toLowerCase()));
  const filteredItems = searchTerm
    ? items.filter((item) => checkMatch(item.label.toLowerCase()))
    : items;
  const shouldShowOther =
    field.allowCustomResponse && (!searchTerm || checkMatch("other"));

  return (
    <>
      <InputWrapper
        className={className}
        ref={refs.setReference}
        aria-invalid={!!error}
        aria-errormessage={error ? errorId : undefined}
      >
        <input
          data-testid="multi-list-dropdown-input"
          ref={innerRef}
          {...getReferenceProps({
            onClick: () => {
              setSearchTerm("");
              onOpenChange(true);
            },
            onKeyDown: (e) => {
              if ((e.key === "Enter" || e.key === " ") && activeIndex != null) {
                e.preventDefault();
                if (activeIndex === items.length) {
                  toggleOther();
                } else if (items[activeIndex]) {
                  onSelect(items[activeIndex]);
                }
              }
            },
          })}
          id={id}
          name={id}
          aria-invalid={!!error}
          aria-errormessage={error ? errorId : undefined}
          aria-autocomplete="list"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Icon
          name="angleDown"
          rotate={open ? 180 : 0}
          size={12}
          dataTestId="multi-list-dropdown-icon"
        />
      </InputWrapper>
      {open && (
        <FloatingFocusManager
          context={context}
          initialFocus={-1}
          visuallyHiddenDismiss
        >
          <ItemList
            {...getFloatingProps({
              ref: refs.setFloating,
              style: floatingStyles,
            })}
          >
            {filteredItems.map((item, idx) => (
              <Item
                key={item.value}
                id={fieldName({ field, index, suffix: item.value })}
                {...getItemProps({
                  ref(node) {
                    listRef.current[idx] = node;
                  },
                })}
                onClick={() => onSelect(item)}
                type="button"
                role="option"
                aria-current={activeIndex === idx}
                aria-checked={selected.includes(item.value)}
              >
                <span title={item.label}>{item.label}</span>
              </Item>
            ))}
            {shouldShowOther && (
              <Item
                id={fieldName({ field, index, suffix: "other" })}
                {...getItemProps({
                  ref(node) {
                    listRef.current[items.length] = node;
                  },
                })}
                onClick={toggleOther}
                type="button"
                role="option"
                aria-current={activeIndex === items.length}
                aria-checked={other}
              >
                <span title="Other">Other</span>
              </Item>
            )}
            <div className="footer">
              <button type="button" onClick={() => onOpenChange(false)}>
                Close
              </button>
            </div>
          </ItemList>
        </FloatingFocusManager>
      )}
      {field.allowCustomResponse && (
        <OtherInput
          ref={otherRef}
          name={fieldName({ field, index, suffix: "other-input" })}
          id={fieldName({ field, index, suffix: "other-input" })}
          aria-label="Other Value"
          type="text"
          value={otherValue ?? ""}
          aria-disabled={!other}
          onChange={(e) => onChange([...selected, e.currentTarget.value])}
          onBlur={onBlur}
        />
      )}
    </>
  );
};

export const MultiListField = (props: FieldProps<HTMLInputElement>) => {
  const { field, index, error, loading, disabled } = props;

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <Label {...field} index={index} />
      <MultiList {...props} />
      <ErrorDisplay uri={field.uri} index={index} error={error} />
    </FieldWrapper>
  );
};
