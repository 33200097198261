import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { IconButton } from "@smart/itops-components-dom";

export const inputStyle = ({ theme }: { theme: Theme }) => css`
  background: ${theme.palette.background.base};
  border: 1px solid ${theme.palette.foreground.highlight};
  border-radius: 3px;

  display: block;
  cursor: text;
  outline: none;
  font-size: ${theme.fontSize.base};
  margin: ${theme.baseUnit * 0.5}rem 0;
  padding: ${theme.baseUnit}rem;

  text-overflow: ellipsis;
  width: 100%;

  &:disabled {
    opacity: 0.6;
  }

  &[aria-invalid="true"] {
    border-color: ${theme.palette.danger.base};
  }

  &:focus,
  &:focus-within {
    border-color: ${theme.palette.primary.base};
  }
`;

export const inputWidth = ({ theme }: { theme: Theme }) => css`
  max-width: ${theme.breakPoints.readable * 0.55}px;
`;

export const TextInput = styled.input`
  ${inputStyle}
  ${inputWidth}
`;
export const InputWrapper = styled.label`
  ${inputStyle}
  ${inputWidth}
  display: flex;
  align-items: center;
  padding: 0 ${(props) => props.theme.baseUnit}rem 0 0;
  gap: ${(props) => props.theme.baseUnit}rem;

  input {
    border: 0;
    padding: ${(props) => props.theme.baseUnit}rem 0
      ${(props) => props.theme.baseUnit}rem
      ${(props) => props.theme.baseUnit}rem;
    background: none;
    font-size: inherit;
    outline: none;
    width: 100%;

    text-overflow: ellipsis;
  }
`;

export const InputButton = styled(IconButton)`
  margin: 0;
  padding: 0;
`;

export const FieldSet = styled.fieldset<{ dir?: "row" | "column" }>`
  background: none;
  border: 0;
  display: flex;
  flex-flow: ${(props) => props.dir || "column"};
  margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  padding: 0;
  width: 100%;
  position: relative;

  .fieldset-value {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    height: 0;
    width: 0;
    opacity: 0;
    overflow: hidden;
    outline: none;
  }
`;
