import styled from "@emotion/styled";
import { useRef } from "react";

import { loadLocale } from "@smart/itops-locale-dom";
import { FieldGroup, TextInput } from "@smart/itops-ui-dom";

import { FieldSet } from "./input";
import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

const DateInputLabel = styled.label`
  .label {
    display: block;
    color: ${(props) => props.theme.palette.foreground.accent};
    font-size: ${(props) => props.theme.fontSize.small};
    margin-bottom: ${(props) => props.theme.baseUnit * 0.1}rem;
  }

  margin: 0 ${(props) => props.theme.baseUnit}rem
    ${(props) => props.theme.baseUnit}rem 0;
`;

export const DateFieldInput = ({
  className,
  field,
  index,
  innerRef,
  value,
  error,
  onChange,
  onBlur,
}: Omit<FieldProps<HTMLInputElement>, "disabled"> & {
  className?: string;
}) => {
  const locale = loadLocale();
  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const [yearValue, monthValue, dayValue] = `${value ?? ""}`
    .replace(/[^\d-]/g, "")
    .split("-");

  const parts = {
    day: {
      label: "Day",
      ref: dayRef,
      value: dayValue,
      minLength: 1,
      maxLength: 2,
      index: 2,
    },
    month: {
      label: "Month",
      ref: monthRef,
      value: monthValue,
      minLength: 1,
      maxLength: 2,
      index: 1,
    },
    year: {
      label: "Year",
      ref: yearRef,
      value: yearValue,
      minLength: 4,
      maxLength: 4,
      index: 0,
    },
  };

  const onChangePart = (part: keyof typeof parts, v: string) => {
    const updated = [yearValue, monthValue, dayValue];
    updated[parts[part].index] = v.replace(/[^\d+]/g, "");

    const updatedValue = updated.map((p) => p || "").join("-");
    onChange(updatedValue === "--" ? "" : updatedValue);
  };
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });

  return (
    <FieldGroup
      id={id}
      error={error}
      hint={field.hint ?? undefined}
      label={<Label {...field} index={index} />}
    >
      <FieldSet
        className={className}
        role="radiogroup"
        aria-invalid={!!error}
        aria-errormessage={error ? errorId : undefined}
        dir="row"
        data-testid="date-field-input"
      >
        <input
          ref={innerRef}
          id={id}
          name={id}
          value={value ?? ""}
          className="fieldset-value"
          aria-invalid={!!error}
          aria-errormessage={error ? errorId : undefined}
          readOnly
          onFocus={() => {
            const part =
              locale.dateFormat.find((p) => !parts[p].value) ||
              locale.dateFormat[2];

            parts[part].ref.current?.focus();
          }}
        />
        {locale.dateFormat.map((part, idx) => (
          <DateInputLabel key={part}>
            <span className="label">{parts[part].label}</span>
            <TextInput
              ref={parts[part].ref}
              id={fieldName({ field, index, suffix: part })}
              name={fieldName({ field, index, suffix: part })}
              aria-invalid={!!error}
              aria-errormessage={error ? errorId : undefined}
              value={parts[part].value ?? ""}
              minLength={parts[part].minLength}
              maxLength={parts[part].maxLength}
              size="base"
              onBlur={onBlur}
              onChange={(e) => {
                onChangePart(part, e.currentTarget.value);
              }}
              inputMode="numeric"
              pattern="\d+"
              fixedWidth={parts[part].minLength > 2 ? 8 : 5}
              onKeyDown={(e) => {
                const prevPart = parts[locale.dateFormat[idx - 1]]?.ref.current;
                const nextPart = parts[locale.dateFormat[idx + 1]]?.ref.current;

                const atStart =
                  e.currentTarget.selectionStart === 0 &&
                  e.currentTarget.selectionEnd === 0;
                const atEnd =
                  e.currentTarget.selectionStart &&
                  e.currentTarget.selectionStart >=
                    e.currentTarget.value.length;

                const goBack = () => {
                  prevPart?.focus();
                  prevPart?.setSelectionRange(
                    value.length,
                    value.length,
                    "backward",
                  );
                };
                const goNext = () => {
                  nextPart?.focus();
                  nextPart?.setSelectionRange(0, 0, "forward");
                };

                if (e.key === "Backspace" && e.currentTarget.value === "") {
                  e.preventDefault();
                  goBack();
                }
                if (
                  e.key.match(/^\d$/) &&
                  atEnd &&
                  e.currentTarget.value.length >= parts[part].maxLength
                ) {
                  goNext();
                }
                if (e.key === "/") {
                  e.preventDefault();
                  if (atEnd) {
                    goNext();
                  }
                }
                if (e.key === "ArrowLeft") {
                  if (atStart) {
                    e.preventDefault();
                    goBack();
                  }
                }
                if (e.key === "ArrowRight") {
                  if (atEnd) {
                    e.preventDefault();
                    goNext();
                  }
                }
              }}
            />
          </DateInputLabel>
        ))}
      </FieldSet>
    </FieldGroup>
  );
};

export const DateField = (props: FieldProps<HTMLInputElement>) => {
  const { loading, disabled } = props;
  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <DateFieldInput {...props} />
    </FieldWrapper>
  );
};
