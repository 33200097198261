import styled from "@emotion/styled";

const AlertWrapper = styled.div<{ type: AlertProps["type"] }>`
  display: flex;
  height: 5.2rem;
  background-color: ${(props) => {
    switch (props.type) {
      case "info":
        return props.theme.scheme.cyan.r10;
      case "warning":
        return props.theme.scheme.yellow.r10;
      case "error":
        return props.theme.scheme.red.r10;
      case "success":
        return props.theme.scheme.green.r10;
      default:
        return props.theme.scheme.cyan.r10;
    }
  }};
  border-radius: 0.8rem;
  margin-bottom: 1rem;

  .left-mark {
    width: 0.4rem;
    background-color: ${(props) => {
      switch (props.type) {
        case "info":
          return props.theme.scheme.blue.r100;
        case "warning":
          return props.theme.scheme.yellow.r100;
        case "error":
          return props.theme.scheme.red.r100;
        case "success":
          return props.theme.scheme.green.r100;
        default:
          return props.theme.scheme.cyan.r100;
      }
    }};
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
  }

  .alert-text {
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.fontSize.base};
    margin-left: 5rem;
  }
`;

type AlertProps = {
  text: string;
  type: "info" | "warning" | "error" | "success";
};

export const Alert = ({ text, type }: AlertProps) => (
  <AlertWrapper type={type}>
    <div className="left-mark" />
    <div className="alert-text">{text}</div>
  </AlertWrapper>
);
