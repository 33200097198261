import { SectionNS, SectionSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const sectionGuard = wrapSchema(SectionSchema);

export const SectionDef = buildTableDef(
  {
    namespace: SectionNS,
    guard: sectionGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
    ttlField: "deletedAtSec",
  } as const,
  {
    byForm: {
      indexName: "byForm",
      keyFields: ["formUri", "order"],
      keySchema: { formUri: "string", order: "string" },
    },
  } as const,
);
