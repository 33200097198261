import { useCallback, useRef, useState } from "react";

import { useAsync } from "@smart/itops-hooks-dom";
import { backoff, entriesOf, v4 } from "@smart/itops-utils-basic";

import { LoadResponses, ResponseSubmitter } from "../types";

const IGNORE_TIME = 15000; // 15s

export const useDBResponses = ({
  submitResponses,
  loadResponses,
  shouldLoad,
}: {
  submitResponses: ResponseSubmitter;
  loadResponses: LoadResponses;
  shouldLoad: boolean;
}) => {
  const operationId = useRef<string>();
  const updateTimestamp = useRef<Record<string, number>>({});
  const [databaseResponses, setDatabaseResponses] = useState<
    Record<string, any> | undefined
  >({});
  const [isLoading, setIsLoading] = useState(false);

  const saveTimestampAndSubmit = useCallback(
    (props: Parameters<ResponseSubmitter>[0]) => {
      const timestamp = Date.now();
      entriesOf(props.responses).forEach(([fieldUri]) => {
        updateTimestamp.current[fieldUri] = timestamp;
      });
      return submitResponses(props);
    },
    [submitResponses],
  );

  useAsync(async () => {
    if (!shouldLoad) return;

    const id = v4();
    operationId.current = id;
    setIsLoading(true);

    const responses = await backoff({
      run: () => loadResponses(),
      test: (found) => {
        const notUpdated = entriesOf(updateTimestamp.current).filter(
          ([fieldUri, timestamp]) =>
            new Date(found?.[fieldUri]?.updatedAt || 0).getTime() < timestamp,
        );

        return notUpdated.length
          ? notUpdated.every(
              ([, timestamp]) => Date.now() - timestamp > IGNORE_TIME,
            )
          : true;
      },
      attempts: 5,
      base: 1000,
      shouldCatch: true,
    })();

    if (operationId.current === id) {
      setIsLoading(false);
      setDatabaseResponses(responses);
    }
  }, [shouldLoad]);

  return {
    saveTimestampAndSubmit,
    databaseResponses,
    isLoading,
  };
};
