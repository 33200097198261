import { apiGateway, apiSchemas } from "@smart/intake-resources-basic";
import { ApiResults, useApi } from "@smart/itops-hooks-dom";

export const intakeApi = useApi(
  apiGateway.prefix,
  apiGateway.lambdaKeys,
  apiGateway.lambdas,
  apiSchemas,
);

export type IntakeApiResults = ApiResults<typeof intakeApi>;

export type IntakeApiMatter = NonNullable<IntakeApiResults["load"]["matter"]>;

export type IntakeApiForm = NonNullable<IntakeApiResults["load"]["form"]>;

export type IntakeApiTeam = NonNullable<IntakeApiResults["load"]["team"]>;

export type IntakeApiSubmission = NonNullable<
  IntakeApiResults["load"]["submission"]
>;

export type IntakeApiAIUserFeedback = NonNullable<
  IntakeApiResults["load"]["aiUserFeedback"]
>;

export type IntakeApiSection = IntakeApiForm["sections"][number];

export type IntakeApiGroup = IntakeApiForm["groups"][number];

export type IntakeApiField = IntakeApiForm["fields"][number];
