import { track as amplitudeTrack } from "@amplitude/analytics-browser";
import { useEffect } from "react";

import { Event, Properties } from "./types";
import { skipTracking } from "./util";

export const track = <E extends Event>(event: E, properties: Properties<E>) => {
  if (skipTracking()) return;
  amplitudeTrack(event, properties);
};

export const trackChanged = <V, E extends Event>(
  value: V,
  event: E,
  properties: (value: V) => Properties<E> | undefined,
) =>
  useEffect(() => {
    const props = properties(value);
    if (props) track(event, props);
  }, [value]);
