import styled from "@emotion/styled";

import { Icon } from "@smart/itops-icons-dom";

import { fieldName, notDisplayErrorMessage } from "../../hooks";

const ErrorWrapper = styled.p`
  display: flex;
  align-items: center;
  white-space: break-spaces;
  color: ${(props) => props.theme.palette.danger.base};

  .exclamation {
    align-self: flex-start;
    margin-right: ${(props) => props.theme.baseUnit * 0.6}rem;
  }
`;

export type ErrorDisplayProps = {
  uri: string;
  index?: number;
  error?: string;
};

export const ErrorDisplay = ({ uri, index, error }: ErrorDisplayProps) =>
  error && error !== notDisplayErrorMessage ? (
    <ErrorWrapper id={fieldName({ field: { uri }, index, suffix: "error" })}>
      <Icon className="exclamation" name="exclamationCircleFilled" size={16} />
      <small>{error}</small>
    </ErrorWrapper>
  ) : null;
