import { system } from "@smart/bridge-types-basic";
import { buildAssetBucketDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { cdn } from "../cloudfront";

const AssetNS = buildNamespace({ system, entity: "Asset" } as const);

export const AssetDef = buildAssetBucketDef({
  cdn,
  prefix: "assets",
  namespace: AssetNS,
});
