import { Modal } from "@smart/itops-ui-dom";

export type IntakePrintUnavailableProps = {
  text: string;
  open: boolean;
  onClose: () => void;
};

export const IntakePrintUnavailable = ({
  text,
  open,
  onClose,
}: IntakePrintUnavailableProps) => (
  <Modal
    open={open}
    onClose={onClose}
    header={{
      text: "Can not save copy of form",
      icon: { library: "lucide", name: "AlertCircle", variant: "header" },
    }}
    footer={{
      right: [{ text: "OK", variant: "cancel" }],
    }}
  >
    <p>{text}</p>
  </Modal>
);
