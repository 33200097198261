import {
  AIUserFeedbackNS,
  AIUserFeedbackSchema,
} from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const aiUserFeedbackGuard = wrapSchema(AIUserFeedbackSchema);

export const AIUserFeedbackDef = buildTableDef(
  {
    namespace: AIUserFeedbackNS,
    guard: aiUserFeedbackGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
    ttlField: "deletedAtSec",
  } as const,
  {
    byForm: {
      indexName: "byForm",
      keyFields: ["formUri"],
      keySchema: { formUri: "string" },
    },
    bySubmission: {
      indexName: "bySubmission",
      keyFields: ["submissionUri"],
      keySchema: { submissionUri: "string" },
    },
    byType: {
      indexName: "byType",
      keyFields: ["type", "updatedAt"],
      keySchema: { type: "string", updatedAt: "string" },
    },
  } as const,
);
