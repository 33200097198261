import styled from "@emotion/styled";
import { ReactNode } from "react";

import { Displayer } from "@smart/itops-editor-dom";
import { ParagraphTag } from "@smart/itops-serialisation-basic";
import { Button } from "@smart/itops-ui-dom";

import { sectionFallback, SectionItem } from "../../types";

const SummarySectionWrapper = styled.section`
  border-bottom: 1px solid
    ${(props) => props.theme.palette.background.highlight};
  padding-bottom: ${(props) => props.theme.baseUnit * 3}rem;
  margin-bottom: ${(props) => props.theme.baseUnit * 3}rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .title {
    display: flex;
    flex-flow: row;
    align-items: center;

    h2 {
      font-size: ${(props) => props.theme.fontSize.heading};
      font-weight: 600;
      margin: ${(props) => props.theme.baseUnit}rem 0;
    }

    button {
      padding: ${(props) => props.theme.baseUnit}rem;
      margin: ${(props) => props.theme.baseUnit}rem 0
        ${(props) => props.theme.baseUnit}rem auto;
    }
  }
`;

export type SummarySectionProps = {
  section: SectionItem;
  onEdit?: () => void;
  children?: ReactNode;
  titleParagraphTag?: ParagraphTag;
};

export const SummarySection = ({
  section,
  onEdit,
  children,
  titleParagraphTag = "h2",
}: SummarySectionProps) => (
  <SummarySectionWrapper>
    <div className="title">
      <Displayer
        paragraphTag={titleParagraphTag}
        value={section.title}
        fallback={sectionFallback}
      />
      {onEdit && (
        <Button
          name="Edit section"
          icon={{ library: "lucide", name: "SquarePen" }}
          onClick={onEdit}
        />
      )}
    </div>
    {children}
  </SummarySectionWrapper>
);
