import { LookupOptions } from "@smart/bridge-intake-components-dom";

import { intakeApi } from "./api";

export const useAddressLookup = (): LookupOptions["addressLookup"] => {
  const getAddress = intakeApi.getAddress();

  return {
    search: ({ search, sessionKey }) => {
      const { status, result } = intakeApi.searchAddress(
        {
          body: { search, sessionKey },
        },
        { required: "search" },
      );
      return { loading: status === "loading", result: result?.addresses || [] };
    },
    load: ({ placeId, sessionKey }) =>
      getAddress.post({ body: { placeId, sessionKey } }),
  };
};
