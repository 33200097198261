import { useEffect, useRef, useState } from "react";

import { FieldGroup } from "@smart/itops-ui-dom";
import { isNullOrUndefined } from "@smart/itops-utils-basic";

import { FieldSet } from "./input";
import { InputItem, InputItemOther, optionsWithFallback } from "./item";
import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

export const RadioField = ({
  field,
  index,
  dir,
  innerRef,
  value,
  error,
  loading,
  disabled,
  onChange,
  onBlur,
}: FieldProps<HTMLInputElement> & { dir?: "column" | "row" }) => {
  const firstItemRef = useRef<HTMLInputElement>(null);
  const otherRef = useRef<HTMLInputElement>(null);
  const [other, setOther] = useState(
    () => !!value && !field.options?.find((o) => o.value === value),
  );
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });

  useEffect(() => {
    if (isNullOrUndefined(value)) setOther(false);
  }, [value]);

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <FieldGroup
        id={id}
        error={error}
        hint={field.hint ?? undefined}
        label={<Label {...field} index={index} />}
      >
        <FieldSet
          role="radiogroup"
          aria-invalid={!!error}
          aria-errormessage={error ? errorId : undefined}
          dir={dir}
        >
          <input
            ref={innerRef}
            id={id}
            name={id}
            value={value ?? ""}
            className="fieldset-value"
            aria-invalid={!!error}
            aria-errormessage={error ? errorId : undefined}
            readOnly
            onFocus={() => {
              if (other) {
                otherRef.current?.focus();
              } else {
                firstItemRef.current?.focus();
              }
            }}
          />
          {optionsWithFallback(field.options).map((option, idx) => (
            <InputItem
              key={option.value}
              uri={field.uri}
              index={index}
              type="radio"
              innerRef={idx === 0 ? firstItemRef : undefined}
              {...option}
              checked={!other && option.value === value}
              error={error}
              onChange={(v) => {
                setOther(false);
                onChange(v);
                onBlur();
              }}
            />
          ))}
          {field.allowCustomResponse && (
            <InputItem
              uri={field.uri}
              index={index}
              type="radio"
              label="Other"
              value="other"
              checked={other}
              error={error}
              onChange={(v) => {
                if (v) {
                  setOther(true);
                  onChange("");
                  onBlur();
                  otherRef.current?.focus();
                } else {
                  setOther(false);
                  onChange(v);
                  onBlur();
                }
              }}
            >
              <InputItemOther
                ref={otherRef}
                id={fieldName({ field, index, suffix: "other-input" })}
                name={fieldName({ field, index, suffix: "other-input" })}
                type="text"
                value={other ? value ?? "" : ""}
                aria-hidden={!other}
                aria-label="Other Value"
                onChange={(e) => onChange(e.currentTarget.value)}
                onBlur={onBlur}
              />
            </InputItem>
          )}
        </FieldSet>
      </FieldGroup>
    </FieldWrapper>
  );
};
