import { parse } from "date-fns";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { z, ZodSchema } from "zod";

import { FieldType, PaymentResponse } from "@smart/bridge-types-basic";
import { AppointmentResponse } from "@smart/bridge-types-basic/src/appointment";
import { loadDefaultCountry } from "@smart/itops-locale-dom";
import { UploadStatus } from "@smart/itops-ui-dom";

// Use this error message when the error state is handled by the field component itself
export const notDisplayErrorMessage = "notDisplayErrorMessage";

export const requiredMessage = "This is a required question";

export type FieldValidator = (response: any) => string | undefined;

const schemaValidator =
  (schema: ZodSchema): FieldValidator =>
  (value) => {
    if (!value) return undefined;

    const parsed = schema.safeParse(value);
    if (parsed.success) return undefined;

    const flattened = parsed.error.flatten();
    return flattened.formErrors[0];
  };

export const fieldValidation: Record<FieldType, FieldValidator | undefined> = {
  address: undefined,
  // At some point, we may want to reintroduce address validation
  // return nullableSmokeballAddress.refine(
  //   (value) =>
  //     !value?.formattedAddress ||
  //     ((value.suburb || value.city) && value.postalCode),
  //   { message: "Please enter a valid address" }
  // );
  checkbox: undefined,
  choice: undefined,
  currency: undefined,
  date: schemaValidator(
    z.preprocess(
      (val) => {
        const parts = (val as string).split("-");
        return parse(
          [
            parseInt(parts[0], 10),
            parseInt(parts[1], 10),
            parseInt(parts[2], 10),
          ].join("-"),
          "yyyy-MM-dd",
          0,
        );
      },
      z
        .date({
          errorMap: (error, ctx) => {
            if (error.code === z.ZodIssueCode.invalid_date) {
              return { ...error, message: "Please enter a valid date" };
            }

            return { ...error, message: error.message || ctx.defaultError };
          },
        })
        .min(new Date("1000-01-01"), "Please enter a valid date"),
    ),
  ),
  email: schemaValidator(
    z.string().email("Please enter a valid email address"),
  ),
  file: (value) => {
    if (value && !Array.isArray(value)) return undefined;

    if (
      value?.some(
        ({ uploadStatus }: { uploadStatus: UploadStatus }) =>
          uploadStatus === "exceedSizeLimit" ||
          uploadStatus === "failedToUpload",
      )
    )
      return notDisplayErrorMessage;
    if (
      value?.some(
        ({ uploadStatus }: { uploadStatus: UploadStatus }) =>
          uploadStatus === "notUploaded" || uploadStatus === "uploading",
      )
    )
      return "Some files are still being uploaded.";
    return undefined;
  },
  info: undefined,
  multilineText: undefined,
  number: undefined,
  phoneNumber: (value) => {
    const valid =
      !value ||
      !value.number ||
      isPossiblePhoneNumber(value.number, loadDefaultCountry());
    return valid ? undefined : "Please enter a valid phone number";
  },
  text: undefined,
  yesNo: undefined,
  appointment: (value: AppointmentResponse) => {
    if (!value) return undefined;
    if (!value.bookedStaff?.length || !value.endTime || !value.startTime) {
      return "Appointment field must be completed";
    }
    return undefined;
  },
  payment: (value: PaymentResponse) => {
    if (!value) return undefined;
    if (!value.chargeRequest) {
      return "Payment must be completed";
    }
    return undefined;
  },
};

export const getRequiredMessage = (fieldType: FieldType) => {
  switch (fieldType) {
    case "checkbox":
      return "Please select all options that apply";
    case "choice":
      return "Please make a selection";
    default:
      return requiredMessage;
  }
};
