import styled from "@emotion/styled";
import { compareAsc, compareDesc } from "date-fns";
import { useMemo, useState } from "react";

import { IconButton } from "@smart/itops-components-dom";
import { Combobox, Label } from "@smart/itops-ui-dom";

import { calendarMonthsShort } from "../../../../types";

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const SelectionGroup = styled.div`
  display: flex;
  gap: 1rem;

  .mobile-display-only {
    font-size: ${(props) => props.theme.fontSize.base};
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    .selection {
      display: none;
    }
    .mobile-display-only {
      display: block;
    }
  }
`;

type MonthPickerProps = {
  currentDate: Date;
  yearRange?: number;
  disabled?: boolean;
  onChange: (selectedDate: Date) => void;
};

export const MonthPicker = ({
  currentDate,
  onChange,
  yearRange = 2,
  disabled,
}: MonthPickerProps) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());

  const yearOptions = useMemo(() => {
    const options = [];
    for (let count = 0; count < yearRange; count += 1) {
      options.push(currentYear + count);
    }

    return options;
  }, [yearRange, currentYear]);
  const earliestDate = new Date(yearOptions[0], 0, 1);
  const latestDate = new Date(yearOptions[yearRange - 1] + 1, 0, 1);

  const isOutOfRange = (date: Date) =>
    compareDesc(date, latestDate) !== 1 ||
    compareAsc(date, earliestDate) === -1;

  const changeMonth = ({
    toMonth,
    toYear,
    byDistance = 0,
  }: {
    toMonth?: number;
    toYear?: number;
    byDistance?: number;
  }) => {
    const year = toYear || selectedYear;
    const month = toMonth === undefined ? selectedMonth : toMonth;
    const date = new Date(year, month + byDistance, 1);

    if (isOutOfRange(date)) return;

    onChange(date);
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());
  };

  return (
    <Container>
      <IconButton
        name="angleLeft"
        onClick={() => !disabled && changeMonth({ byDistance: -1 })}
        disabled={disabled}
        aria-label="Previous month"
      />
      <SelectionGroup>
        <Label className="mobile-display-only">
          {calendarMonthsShort[selectedMonth]}
        </Label>
        <Label className="mobile-display-only">{selectedYear}</Label>
        <Combobox
          className="selection"
          title="Month"
          options={calendarMonthsShort}
          disableClearable
          value={calendarMonthsShort[selectedMonth]}
          size="base"
          multiple={false}
          onChange={(_, v) => {
            const month = calendarMonthsShort.findIndex((m) => m === v);
            changeMonth({ toMonth: month });
          }}
          disabled={disabled}
        />
        <Combobox
          className="selection"
          title="Year"
          options={yearOptions}
          value={selectedYear}
          getOptionLabel={(v) => v.toString()}
          disableClearable
          multiple={false}
          size="base"
          onChange={(_, v) => {
            changeMonth({ toYear: v });
          }}
          disabled={disabled}
        />
      </SelectionGroup>
      <IconButton
        name="angleRight"
        onClick={() => !disabled && changeMonth({ byDistance: 1 })}
        disabled={disabled}
        aria-label="Next month"
      />
    </Container>
  );
};
