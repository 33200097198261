import { z } from "zod";

import { system } from "@smart/bridge-types-basic";
import { buildSNSSQSDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const ActivityHeartbeatNS = buildNamespace({
  system,
  entity: "ActivityHeartbeat",
} as const);

export const ActivityHeartbeatSchema = z.object({
  userId: z.string(),
  createdAt: z.string(),
});

const activityHeartbeatGuard = wrapSchema(ActivityHeartbeatSchema);

export const ActivityHeartbeatDef = buildSNSSQSDef({
  namespace: ActivityHeartbeatNS,
  guard: activityHeartbeatGuard,
  maxAttempts: 1,
  deliveryDelay: 600,
  sqsOnly: true,
  groupKey: undefined,
  idKey: undefined,
});
