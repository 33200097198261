import {
  TableTypes,
  TableIndexKeys,
  TableKeys,
  TableIndexKeyValues,
} from "@smart/itops-constructs-basic";
import { namespacedList } from "@smart/itops-types-basic";
import { keysOf, mapEntries } from "@smart/itops-utils-basic";

import { AIUserFeedbackDef } from "./ai-user-feedback";
import { FieldDef } from "./field";
import { FormDef } from "./form";
import { FormMatterTypeDef } from "./form-matter-type";
import { GroupDef } from "./group";
import { ResponseDef } from "./response";
import { SectionDef } from "./section";
import { SettingDef } from "./setting";
import { SlugDef } from "./slug";
import { SubmissionDef } from "./submission";
import { SubmissionLinkHashDef } from "./submission-link-hash";
import { TeamDef } from "./team";
import { TrackerDef } from "./tracker";
import { UserDef } from "./user";
import { UserActivityStateDef } from "./user-activity-state";

export const tables = namespacedList({
  Field: FieldDef,
  Form: FormDef,
  FormMatterType: FormMatterTypeDef,
  Group: GroupDef,
  SubmissionLinkHash: SubmissionLinkHashDef,
  Response: ResponseDef,
  Section: SectionDef,
  Setting: SettingDef,
  Slug: SlugDef,
  Submission: SubmissionDef,
  Team: TeamDef,
  Tracker: TrackerDef,
  User: UserDef,
  UserActivityState: UserActivityStateDef,
  AIUserFeedback: AIUserFeedbackDef,
});

export type Tables = typeof tables;

export const tableKeys = keysOf(tables);

export type EntityKey = keyof Tables;

export type EntityTypes = TableTypes<Tables>;

export type EntityKeys = TableKeys<Tables>;

export type EntityIndexKeys = TableIndexKeys<Tables>;

export type EntityIndexKeyValues = TableIndexKeyValues<Tables>;

export type TField = EntityTypes["Field"];
export type TForm = EntityTypes["Form"];
export type TFormMatterType = EntityTypes["FormMatterType"];
export type TSubmissionLinkHash = EntityTypes["SubmissionLinkHash"];
export type TGroup = EntityTypes["Group"];
export type TResponse = EntityTypes["Response"];
export type TSection = EntityTypes["Section"];
export type TSlug = EntityTypes["Slug"];
export type TSubmission = EntityTypes["Submission"];
export type TTeam = EntityTypes["Team"];
export type TTracker = EntityTypes["Tracker"];
export type TUser = EntityTypes["User"];
export type TSetting = EntityTypes["Setting"];
export type TUserActivityState = EntityTypes["UserActivityState"];
export type TAIUserFeedback = EntityTypes["AIUserFeedback"];

export type TableNS = { [k in EntityKey]: Tables[k]["namespace"] };

export const tableNS: TableNS = mapEntries(tables, (t) => t.namespace);
