import { z } from "zod";

import { planInfoSchema, planRoutingSchema } from "@smart/bridge-domains-basic";
import { system } from "@smart/bridge-types-basic";
import { buildSNSSQSDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const PlanNS = buildNamespace({ system, entity: "Plan" } as const);

export const PlanSchema = z
  .object({
    info: planInfoSchema,
    steps: z.array(
      z.object({
        step: z.string(),
        args: z.object({}).passthrough(),
      }),
    ),
  })
  .merge(planRoutingSchema);

const planGuard = wrapSchema(PlanSchema);

export const PlanDef = buildSNSSQSDef({
  namespace: PlanNS,
  guard: planGuard,
  maxAttempts: 1,
  sqsOnly: false,
  groupKey: "shardKey",
  idKey: "operationId",
});
