import { withLDProvider } from "launchdarkly-react-client-sdk";
import { ComponentType } from "react";

import { loadEnv } from "@smart/bridge-env-dom";

import {
  withLocalSmokeballFeatureFlags,
  withSmokeballFeatureFlags,
} from "./sb-feature-flag-provider";
import { skipFeatureFlags } from "./utils";

export const withFeatureFlags = (app: ComponentType) => {
  if (skipFeatureFlags()) return withLocalSmokeballFeatureFlags(app);

  return withLDProvider({
    clientSideID: loadEnv("LaunchDarklyClientSideId"),
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(withSmokeballFeatureFlags(app));
};
