import { system } from "@smart/bridge-types-basic";
import { buildAssetBucketDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { cdn } from "../cloudfront";

const QuestionGenerationFilesNS = buildNamespace({
  system,
  entity: "QuestionGenerationFiles",
} as const);

export const QuestionGenerationFilesDef = buildAssetBucketDef({
  cdn,
  prefix: "questionGenerationFiles",
  namespace: QuestionGenerationFilesNS,
  lifecycleRules: [
    { expirationDays: 8, tagFilters: { expiration: "8d" } },
    { expirationDays: 180, tagFilters: { expiration: "180d" } },
  ],
});
