import { useOrderedItems } from "./ordered-items";
import { Visibility } from "./visibility";
import { FieldItem, GroupItem, OrderedSection, SectionItem } from "../types";

export type OrderedSectionsOptions = {
  sections: SectionItem[];
  groups: GroupItem[];
  fields: FieldItem[];
  visibility?: Visibility;
};

export const useOrderedSections = ({
  sections,
  groups,
  fields,
  visibility,
}: OrderedSectionsOptions): OrderedSection[] =>
  sections
    .filter((s) => !visibility || !!visibility.visibleSections[s.uri])
    .map((section) => ({
      section,
      orderedItems: useOrderedItems({
        groups,
        fields,
        visibility,
        selected: section,
      }),
    }))
    .filter((s) => !!s.orderedItems.length);
