import styled from "@emotion/styled";
import { useEffect } from "react";

import { Icon } from "@smart/itops-icons-dom";
import { Button } from "@smart/itops-ui-dom";

const ConnectionBannerWrapper = styled.div<{ error: boolean }>`
  background-color: ${(props) =>
    props.error ? props.theme.scheme.red.r10 : props.theme.scheme.green.r20};

  font-size: ${(props) => props.theme.fontSize.base};
  min-height: 4rem;
  display: flex;
  padding: 1rem 3rem;
  justify-content: center;

  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    max-width: 75rem;

    .banner-info {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .banner-actions {
      display: flex;
      gap: 1rem;

      .try-again-button {
        font-weight: 400;
      }
    }

    button {
      padding: 0;
      background-color: transparent;
    }

    .banner-icon {
      color: ${(props) =>
        props.error
          ? props.theme.scheme.red.r100
          : props.theme.scheme.green.r100};
    }
  }
`;

type ConnectionBannerProps = {
  connectionError: boolean;
  showConnectionBanner?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];
  retry: (() => void) | undefined;
};
export const ConnectionBanner = ({
  connectionError,
  showConnectionBanner,
  retry,
}: ConnectionBannerProps) => {
  useEffect(() => {
    if (connectionError && showConnectionBanner) {
      showConnectionBanner[1](true);
    }
  }, [connectionError]);

  return (
    showConnectionBanner &&
    showConnectionBanner[0] && (
      <ConnectionBannerWrapper error={connectionError}>
        <div className="banner">
          <div className="banner-info">
            <Icon
              library="lucide"
              name={connectionError ? "AlertCircle" : "CheckCircle2"}
              className="banner-icon"
            />
            {connectionError
              ? "Failed to save your form."
              : "Your changes will be saved automatically as you complete the form."}
          </div>
          <div className="banner-actions">
            {connectionError && (
              <Button
                className="try-again-button"
                text="Try again"
                variant="navigation"
                onClick={retry}
              />
            )}
            <Button
              icon={{ library: "lucide", name: "X" }}
              onClick={() => showConnectionBanner[1](false)}
            />
          </div>
        </div>
      </ConnectionBannerWrapper>
    )
  );
};
