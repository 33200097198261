import { z } from "zod";

import { system } from "@smart/bridge-types-basic";
import { buildSNSSQSDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const MigrateNS = buildNamespace({
  system,
  entity: "Migrate",
} as const);

export const MigrateSchema = z.object({
  channel: z.string(),
  ts: z.string().optional(),
  iteration: z.number().optional(),
});

const migrateGuard = wrapSchema(MigrateSchema);

export const MigrateDef = buildSNSSQSDef({
  namespace: MigrateNS,
  guard: migrateGuard,
  maxAttempts: 1,
  sqsOnly: false,
  groupKey: undefined,
  idKey: undefined,
});
