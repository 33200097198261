import { defaultEnv, EnvKey } from "./types";
import { uxEnv, UxKey } from "./ux";
import { values } from "./variables.config";

export const loadEnv = (key: EnvKey) => {
  if (isLocalServer) return defaultEnv[key];
  if (isUXMode) return uxEnv[isUXMode as UxKey][key];

  return values[key];
};
