import { useParamsWithDefault } from "@smart/itops-hooks-dom";

import { SubmissionLoadView } from "../components";

export const Submission = () => {
  const submissionInfo = useParamsWithDefault((p) => ({
    slug: p.slug || "",
    id: p.id || "",
  }));

  return <SubmissionLoadView {...submissionInfo} />;
};
