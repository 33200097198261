import { system } from "@smart/bridge-types-basic";
import { buildAssetBucketDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { cdn } from "../cloudfront";

const IntakeUploadedFilesNS = buildNamespace({
  system,
  entity: "IntakeUploadedFiles",
} as const);

export const IntakeUploadedFilesDef = buildAssetBucketDef({
  cdn,
  prefix: "intakeUploadedFiles",
  namespace: IntakeUploadedFilesNS,
});
