import { z } from "zod";

import { planInfoSchema } from "@smart/bridge-domains-basic";
import { system } from "@smart/bridge-types-basic";
import { buildSNSSQSDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const SmokeballIntegrationNS = buildNamespace({
  system,
  entity: "SmokeballIntegration",
} as const);

export const SmokeballIntegrationSchema = z.object({
  operationId: z.string(),
  shardKey: z.string(),
  info: planInfoSchema,
  action: z.object({
    key: z.string(),
    args: z.object({}).passthrough(),
  }),
});

const smokeballIntegrationGuard = wrapSchema(SmokeballIntegrationSchema);

export const SmokeballIntegrationDef = buildSNSSQSDef({
  namespace: SmokeballIntegrationNS,
  guard: smokeballIntegrationGuard,
  sqsOnly: false,
  groupKey: "shardKey",
  idKey: "operationId",
  maxAttempts: 1,
  visibilityTimeout: 360,
});
