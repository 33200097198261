import { namespacedList } from "@smart/itops-types-basic";
import { keysOf } from "@smart/itops-utils-basic";

import { AssetDef } from "./asset";
import { IntakeUploadedFilesDef } from "./intake-uploaded-files";
import { QuestionGenerationFilesDef } from "./question-generation-files";

export const buckets = namespacedList({
  Asset: AssetDef,
  IntakeUploadedFiles: IntakeUploadedFilesDef,
  QuestionGenerationFiles: QuestionGenerationFilesDef,
});

export const bucketKeys = keysOf(buckets);

export type Buckets = typeof buckets;
