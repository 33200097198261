import { Pill, smokeballTheme } from "@smart/itops-smokeball-components-dom";

import { betaColour } from "../../types";

export const BetaPill = () => (
  <div className="beta-pill">
    <Pill
      text="BETA"
      styles={{
        borderColor: "transparent",
        backgroundColor: betaColour,
        color: smokeballTheme.palette.background.base,
        fontWeight: 600,
        size: 4,
      }}
    />
  </div>
);
