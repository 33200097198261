import { GroupNS, GroupSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const groupGuard = wrapSchema(GroupSchema);

export const GroupDef = buildTableDef(
  {
    namespace: GroupNS,
    guard: groupGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
    ttlField: "deletedAtSec",
  } as const,
  {
    byForm: {
      indexName: "byForm",
      keyFields: ["formUri", "order"],
      keySchema: { formUri: "string", order: "string" },
    },
    bySection: {
      indexName: "bySection",
      keyFields: ["sectionUri", "order"],
      keySchema: { sectionUri: "string", order: "string" },
    },
  } as const,
);
