import { FormNS, FormSchema } from "@smart/bridge-types-basic";
import { buildTableDef } from "@smart/itops-constructs-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

const formGuard = wrapSchema(FormSchema);

export const FormDef = buildTableDef(
  {
    namespace: FormNS,
    guard: formGuard,
    keyFields: ["uri"],
    keySchema: { uri: "string" },
    ttlField: "deletedAtSec",
  } as const,
  {
    byOwner: {
      indexName: "byOwner",
      keyFields: ["teamUri", "updatedAt"],
      keySchema: { teamUri: "string", updatedAt: "string" },
    },
    byCategory: {
      indexName: "byCategory",
      keyFields: ["category", "teamUri"],
      keySchema: { category: "string", teamUri: "string" },
    },
  } as const,
);
