import { AWSInfo, InfraInfo, InfoKeys } from "@smart/itops-utils-basic";

export const defaultEnvKeys = [
  "AWSAccountId",
  "AWSRegion",
  "Env",
  "Region",
] as const;

export type DefaultEnvKey = (typeof defaultEnvKeys)[number];
export type DefaultEnvKV = { [key in DefaultEnvKey]: string };

export const envKeys = [
  "CdnPrivateKey",
  "CdnPublicKey",
  "FamilyProApiHost",
  "FamilyProApiUrl",
  "FamilyProApiToken",
  "SmokeballDefaultAccountId",
  "SmokeballAccountId",
  "SmokeballUserPoolId",
  "SmokeballClientId",
  "SmokeballApiHost",
  "SmokeballAiApiHost",
  "SmokeballPaymentHost",
  "SmokeballApiKey",
  "SmokeballTokenHost",
  "SmokeballTokenClientId",
  "SmokeballTokenClientSecret",
  "CommunicateUserPoolId",
  "CommunicateClientId",
  "GoogleApiKey",
  "GoogleProjectId",
  "GoogleMapCountryCode",
  "LaunchDarklySdkKey",
  "SmokeballLaunchDarklySdkKey",
  "AzureOpenAiKey",
  "AzureOpenAiBasePath",
  "LogManagementToolAccessKey",
  "LogManagementToolIngressHttpEndpoint",
  "SlackBotToken",
  "SlackDlqChannel",
  "BotAccountId",
] as const;

export type EnvKey = (typeof envKeys)[number];
export type EnvKV = { [key in EnvKey]: string };

export const awsInfoKeys: InfoKeys<AWSInfo, DefaultEnvKey> = {
  accountId: "AWSAccountId",
  region: "AWSRegion",
};

export const infraInfoKeys: InfoKeys<InfraInfo, DefaultEnvKey> = {
  env: "Env",
  region: "Region",
};

export const smokeballPoolKeys: EnvKey[] = [
  "SmokeballUserPoolId",
  "SmokeballClientId",
];
export const communicatePoolKeys: EnvKey[] = [
  "CommunicateUserPoolId",
  "CommunicateClientId",
];
export const smokeballApiKeys: EnvKey[] = [
  "SmokeballAccountId",
  "SmokeballApiHost",
  "SmokeballPaymentHost",
  "SmokeballApiKey",
  "SmokeballTokenHost",
  "SmokeballTokenClientId",
  "SmokeballTokenClientSecret",
];

export const azureOpenAiApiKeys: EnvKey[] = [
  "AzureOpenAiBasePath",
  "AzureOpenAiKey",
];

export const familyProApiKeys: EnvKey[] = [
  "FamilyProApiHost",
  "FamilyProApiUrl",
  "FamilyProApiToken",
];

export const googleMapApiKeys: EnvKey[] = [
  "GoogleApiKey",
  "GoogleMapCountryCode",
];

export const googleCaptchaApiKeys: EnvKey[] = [
  "GoogleApiKey",
  "GoogleProjectId",
];

export const slackApiKeys: EnvKey[] = ["SlackBotToken", "SlackDlqChannel"];

export const launchDarklyKeys: EnvKey[] = [
  "LaunchDarklySdkKey",
  "SmokeballLaunchDarklySdkKey",
];
