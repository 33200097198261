import { useRoutes } from "react-router-dom";

import { CreateSubmission } from "./create";
import { Submission } from "./submission";
import { NotFound } from "../components";

const RouteSelector = () => {
  const routes = [
    { path: "/create/:encodedParam", element: <CreateSubmission /> },
    { path: "/create/:teamId/:slugId", element: <CreateSubmission /> },
    { path: "/submission/:id", element: <Submission /> },
    { path: "/:slug/:id", element: <Submission /> },
    { path: "*", element: <NotFound /> },
  ];

  return useRoutes(routes);
};

export { RouteSelector };
