import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, MemoryRouter } from "react-router-dom";

import { intakeTheme } from "@smart/bridge-intake-components-dom";
import { ReCaptchaProvider } from "@smart/bridge-recaptcha-dom";
import { ThemeProvider, GlobalStyles } from "@smart/itops-components-dom";
import { HistoryProvider } from "@smart/itops-hooks-dom";

import { RouteSelector } from "./routes";

export const IntakeApp = () => (
  <HelmetProvider>
    <ThemeProvider theme={intakeTheme}>
      <GlobalStyles />
      <BrowserRouter>
        <HistoryProvider>
          <ReCaptchaProvider>
            <RouteSelector />
          </ReCaptchaProvider>
        </HistoryProvider>
      </BrowserRouter>
    </ThemeProvider>
  </HelmetProvider>
);

export const IntakeTestingApp = ({
  initialEntries,
}: {
  initialEntries?: string[];
}) => (
  <HelmetProvider>
    <ThemeProvider theme={intakeTheme}>
      <GlobalStyles />
      <MemoryRouter initialEntries={initialEntries}>
        <RouteSelector />
      </MemoryRouter>
    </ThemeProvider>
  </HelmetProvider>
);
