import styled from "@emotion/styled";

import { Label } from "./label";
import { FieldProps } from "./wrapper";

const LabelWrapper = styled.div`
  background: ${(props) => props.theme.scheme.grey.r20};
  border-radius: 1rem;
`;
export const InfoField = ({ field }: FieldProps) => (
  <LabelWrapper>
    <Label {...field} />
  </LabelWrapper>
);
