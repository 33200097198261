import styled from "@emotion/styled";

import { FontSizeKey } from "@smart/itops-ui-dom";

export const Indicator = styled.span<{ size?: FontSizeKey }>`
  color: ${(props) => props.theme.scheme.red.r100};
  font-size: ${(props) => props.theme.fontSize[props.size || "base"]};

  &::before {
    content: " *";
  }
`;
