import { useNavigate } from "react-router-dom";

import { track } from "@smart/bridge-metrics-dom";
import { useReCaptcha } from "@smart/bridge-recaptcha-dom";
import { useAsync } from "@smart/itops-hooks-dom";
import { isNotNullOrUndefined } from "@smart/itops-utils-basic";

import { intakeApi } from "./api";

export const useRiskAnalysis = () => {
  const { executeRecaptcha, siteKey } = useReCaptcha();
  const recaptcha = intakeApi.recaptcha();
  const navigate = useNavigate();

  useAsync(async () => {
    if (!executeRecaptcha) return;

    const token = await executeRecaptcha("LOAD_INTAKE_FORM");
    const result = await recaptcha.post({
      body: { token, siteKey, expectedAction: "LOAD_INTAKE_FORM" },
    });

    const riskScore = result?.riskAnalysis.score;
    if (isNotNullOrUndefined(riskScore) && riskScore < 0.3) {
      track("Invalid Link", { link: window.location.pathname, riskScore });
      navigate("/invalid");
    }
  }, []);
};
